import { Ng2StateDeclaration } from '@uirouter/angular';
import { DocumentsShowComponent } from './containers/document-show/document-show.component';
import { ViewManageQcReviewComponent } from '../qc-reviews/components/view-manage-qc-review/view-manage-qc-review.component';

const commonData = { requiresAuth: true };

export const documentsRoute: Ng2StateDeclaration [] = [
    {
        name: 'app.team.document-show',
        url: '/documents/:documentId/versions/:version?taskId&contentVersion&openRequestSignatureModal',
        component: DocumentsShowComponent,
        data: commonData
    },
    {
        name: 'app.team.document-show-most-recent',
        url: '/documents/:documentId?taskId&contentVersion&signatureRequestId?monitorReviewId',
        component: DocumentsShowComponent,
        data: commonData
    },
    {
        name: 'app.team.view-manage-qc-review',
        url: '/documents/:documentId/versions/:version/manage-qc-review',
        component: ViewManageQcReviewComponent,
        data: commonData
    }
];
