
import { Ng2StateDeclaration } from '@uirouter/angular';
import { ManageTagsComponent } from './containers/manage-tags/manage-tags.component';

const commonData = { requiresAuth: true };

export const tagsRoute: Ng2StateDeclaration [] = [
    {
        name: 'app.team.manage-tags',
        url: '/manage-tags',
        component: ManageTagsComponent,
        data: commonData
    }
];
