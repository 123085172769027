import { Ng2StateDeclaration } from '@uirouter/angular';
import { ReportsIndexComponent } from './containers/reports-index/reports-index.component';

const commonData = { requiresAuth: true };

export const reportsRoutes: Ng2StateDeclaration [] = [
    {
        name: 'app.team.reports',
        url: '/reports/:reportId',
        params: {
            reportId: {
                value: '',
                dynamic: true
            }
        },
        component: ReportsIndexComponent,
        data: commonData
    }
];
