import { Ng2StateDeclaration } from '@uirouter/angular';
import { TeamInboxComponent } from './containers/team-inbox/team-inbox.component';
import { InboxDocumentPreviewComponent } from './containers/inbox-document-preview/inbox-document-preview.component';

const commonData = { requiresAuth: true };


export const teamsInboxRoutes: Ng2StateDeclaration [] = [
    {
        name: 'app.team.team-inbox',
        url: '/team-inbox',
        component: TeamInboxComponent,
        data: commonData
    },
    {
        name: 'app.team.inbox-document-preview',
        url: '/inbox/documents/:documentId/versions/:version',
        params: {
            documentId: null,
            version: null
        },
        component: InboxDocumentPreviewComponent
    }
];
