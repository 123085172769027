import { Ng2StateDeclaration } from '@uirouter/angular';
import { ROUTES } from '@app/core/constants';
import { BindersIndexComponent } from './containers/binders-index/binders-index.component';

const commonData = { requiresAuth: true };


export const bindersRoutes: Ng2StateDeclaration [] = [
    {
        name: ROUTES.binders,
        url: '/binders',
        component: BindersIndexComponent,
        data: commonData
    }
];
