// Modules for this file
import * as angular from 'angular';
import angularAria from 'angular-aria';
import { upgradeModule } from '@uirouter/angular-hybrid';
import * as uiBootstrap from 'angular-ui-bootstrap';
import uiBootstrapDateTime from 'bootstrap-ui-datetime-picker';

// App modules
import { folderizer } from './components/folderizer';
import auditTrail from './components/audit-trail';
import passwordPolicy from './components/password-policy';
import { signingPinPolicyModule } from './components/signing-pin-policy';
import { signatureRequestsModule } from './components/signature-requests';
import { duplicateModule } from './components/duplicate';
import { needHelpModule } from './components/need-help';
import appConfig from './shared/app-config';

import layout from './layout';
import core from './core';
import shared from './shared';
import widgets from './widgets';

const appModule = angular
    .module('fhc', [
        'ui.router',
        upgradeModule.name,
        uiBootstrap,
        uiBootstrapDateTime,
        angularAria,
        core.name,
        shared.name,
        widgets.name,
        layout.name,
        folderizer.name,
        auditTrail.name,
        folderizer.name,
        auditTrail.name,
        passwordPolicy.name,
        signingPinPolicyModule.name,
        signatureRequestsModule.name,
        duplicateModule.name,
        needHelpModule.name,
        appConfig.name
    ]);

export default appModule;
