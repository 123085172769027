import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EntityModule } from '@app/widgets/entity/entity.module';
import { WidgetsModule } from '@app/widgets/widgets.module';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { SharedModule } from '@app/shared/shared.module';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { UIRouterModule } from '@uirouter/angular';
import { CreateTagComponent } from './components/create-tag/create-tag.component';
import { TagsService } from './tags.service';
import { UpdateTagComponent } from './components/update-tag/update-tag.component';
import { DeleteTagComponent } from './components/delete-tag/delete-tag.component';
import { ManageTagsComponent } from './containers/manage-tags/manage-tags.component';
import { tagsRoute } from './tags.routes';

@NgModule({
    imports: [
        UIRouterModule.forChild({ states: tagsRoute }),
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        BsDropdownModule.forRoot(),
        TooltipModule.forRoot(),
        CommonModule,
        WidgetsModule,
        EntityModule,
        SharedModule
    ],
    providers: [
        TagsService
    ],
    declarations: [
        CreateTagComponent,
        DeleteTagComponent,
        UpdateTagComponent,
        ManageTagsComponent
    ],
    entryComponents: [
        CreateTagComponent,
        UpdateTagComponent,
        DeleteTagComponent,
        ManageTagsComponent
    ],
    exports: [
    ]
})
export class TagsModule {}
