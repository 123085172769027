import { Ng2StateDeclaration } from '@uirouter/angular';
import { ConnectedTeamsComponent } from './containers/connected-teams/connected-teams.component';


const commonData = { requiresAuth: true };


export const connectedTeamsRoute: Ng2StateDeclaration [] = [
    {
        name: 'app.team.connected-teams',
        url: '/document-sharing/connected-teams',
        component: ConnectedTeamsComponent,
        data: commonData
    }
];
