
import { Ng2StateDeclaration } from '@uirouter/angular';
import { StudyViewComponent } from './containers/study-view/study-view.component';
import { MonitorGroupsViewComponent } from './containers/monitor-groups/monitor-groups-view.component';
import { MonitorGroupFormComponent } from './containers/monitor-group-form/monitor-group-form.component';

const commonData = { requiresAuth: true };


export const studiesRoute: Ng2StateDeclaration [] = [
    {
        name: 'app.team.study-create',
        url: '/studies/new',
        component: StudyViewComponent,
        data: commonData
    },
    {
        name: 'app.team.manage-monitor-groups',
        url: '/monitor-groups',
        component: MonitorGroupsViewComponent,
        data: commonData
    },
    {
        name: 'app.team.monitor-group-form',
        url: '/edit-monitor-groups/:monitorGroupId?isDuplicate',
        params: {
            monitorGroupId: '',
            isDuplicate: 'false'
        },
        component: MonitorGroupFormComponent,
        data: commonData
    },
    {
        name: 'app.team.study-edit',
        url: '/studies/:studyId/edit',
        component: StudyViewComponent,
        data: commonData
    },
    {
        name: 'app.team.study-connect',
        url: '/studies/:studyId/connect',
        component: StudyViewComponent,
        data: {
            ...commonData,
            tab: 'studyDetails'
        }
    }
];
