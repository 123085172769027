
import { Ng2StateDeclaration } from '@uirouter/angular';
import { ROUTES } from '@app/core/constants';
import { LogTemplateTypeSelectorComponent } from './components/log-template-type-selector/log-template-type-selector.component';
import { LogTemplateType } from './components/log-template-type-selector/log-template-type-selector.component.types';
import { TeamLogTemplatesComponent } from './containers/team-log-templates/team-log-templates.component';
import { LogTemplateViewComponent } from './containers/log-template-view/log-template-view.component';
import { DoaLogTemplateCreateComponent } from './containers/doa-log-template-create/doa-log-template-create.component';
import { DoaLogTemplateViewComponent } from './containers/doa-log-template-view/doa-log-template-view.component';

const commonData = { requiresAuth: true };


export const logTemplatesRoute: Ng2StateDeclaration [] = [
    {
        name: ROUTES.logTemplatesSelector,
        url: '/log-templates',
        component: LogTemplateTypeSelectorComponent,
        data: commonData
    },
    {
        name: ROUTES.elogs,
        url: '/log-templates/elog',
        component: TeamLogTemplatesComponent,
        data: {
            ...commonData,
            logType: LogTemplateType.ELOG
        }
    },
    {
        name: ROUTES.doaLogs,
        url: '/log-templates/doa',
        component: TeamLogTemplatesComponent,
        data: {
            ...commonData,
            logType: LogTemplateType.DOA
        }
    },
    {
        name: ROUTES.logTemplateCreate,
        url: '/log-templates/elog/new',
        component: LogTemplateViewComponent
    },
    {
        name: ROUTES.doaLogTemplateCreate,
        url: '/log-templates/doa/new',
        component: DoaLogTemplateCreateComponent
    },
    {
        name: ROUTES.logTemplateView,
        url: '/log-templates/elog/:logTemplateId',
        component: LogTemplateViewComponent
    },
    {
        name: ROUTES.doaLogTemplateView,
        url: '/log-templates/doa/:logTemplateId',
        component: DoaLogTemplateViewComponent
    },
    {
        name: ROUTES.logTemplateViewVersion,
        url: '/log-templates/elog/:logTemplateId/versions/:version',
        component: LogTemplateViewComponent
    },
    {
        name: ROUTES.doaLogTemplateViewVersion,
        url: '/log-templates/doa/:logTemplateId/versions/:version',
        component: DoaLogTemplateViewComponent
    }
];
