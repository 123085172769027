
import { Ng2StateDeclaration } from '@uirouter/angular';
import { SessionsActivityShowComponent } from './containers/session-activity-show/sessions-activity-show.component';

const commonData = { requiresAuth: true };


export const sessionRoute: Ng2StateDeclaration [] = [
    {
        name: 'app.team.session-activity-show',
        url: '/session-activity',
        component: SessionsActivityShowComponent,
        data: commonData
    }
];
