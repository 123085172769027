import {
    Component,
    Input,
    OnChanges,
    SimpleChanges,
    Output,
    EventEmitter, OnInit
} from '@angular/core';
import { of } from 'rxjs';
import { PaginationPropertiesService } from '@app/shared/pagination-properties/pagination-properties.service';
import { FeatureFlagService } from '@app/core/feature-flag.service';
import { FEATURE_FLAGS } from '@app/core/constants/feature-flags';
import {
    debounceTime,
    filter,
    tap,
    catchError
} from 'rxjs/operators';

import { AuditTrailService } from '@app/shared/audit-trail/audit-trail.service';
import { ModalsService } from '@app/shared/modal-helper/modals.service';
import { CurrentSessionService } from '@app/core/current-session.service';
import { StateService } from '@uirouter/core';
import { ApiErrorsService } from '@app/shared/api-error/api-errors.service';

import {
    Study,
    Team,
    AuditTrailSubject,
    CursorPaginationResponse,
    AuditTrail
} from '@app/shared/models';

import { AuditTrailModalComponent } from '@app/components/audit-trail/components/audit-trail-modal/audit-trail-modal.component';
import { AuditTrailModalItem, AuditTrailTypes } from '@app/components/audit-trail/components/audit-trail-modal/audit-trail-modal.component.types';
import { Pagination } from './manage-team-studies.types';
import template from './manage-team-studies.component.html';
import style from './manage-team-studies.component.scss';

interface PaginationWithFilter extends Pagination {
    searchField: string;
    searchTerm: string;
}
@Component({
    selector: 'manage-team-studies',
    template,
    styles: [String(style)]
})
export class ManageTeamStudiesComponent implements OnInit, OnChanges {
    @Input() canEdit: boolean;
    @Input() canManageMonitorGroups: boolean;
    @Input() studyData: {
        items: Study[];
        recordCount: number;
    };

    @Output() pageUpdated = new EventEmitter<PaginationWithFilter>();
    @Output() filterUpdated = new EventEmitter<PaginationWithFilter>();
    studies: Study[];
    selectedStudyId: string|undefined = undefined;
    isFilterEnabled: boolean;
    loading = true;
    maxSize: number;
    showBoundaryLinkNumbers: boolean;
    searchInput = '';
    pagination: Pagination = {
        pageNum: 1,
        pageSize: 20,
        sortBy: 'uniqueProtocolId',
        sortDir: 'ASC'
    };

    _currentTeam: Team;
    canViewAudits = false;
    constructor(
        private paginationProperties: PaginationPropertiesService,
        private currentSession: CurrentSessionService,
        private state: StateService,
        private auditTrails: AuditTrailService,
        private modals: ModalsService,
        private apiErrors: ApiErrorsService,
        private featureFlags: FeatureFlagService
    ) {
        this.showBoundaryLinkNumbers = this.paginationProperties.showBoundaryLinkNumbers();
        this.maxSize = this.paginationProperties.getMaxSize();
    }

    ngOnInit(): void {
        this._currentTeam = this.currentSession.getCurrentTeam();

        if (!this._currentTeam.permissions || !this._currentTeam.permissions.viewTeamStudyProfiles) {
            this.state.go('app.select-team');
        }

        this.featureFlags.getFlag(FEATURE_FLAGS.EBINDERS_SEARCH_STUDY_PAGE, false).pipe(
            filter((flag) => flag !== undefined),
            debounceTime(300),
            tap((isFlagEnabled) => {
                this.isFilterEnabled = isFlagEnabled;
            })
        ).subscribe();
        this.canViewAudits = this._currentTeam.permissions.manageTeam || this._currentTeam.permissions.viewTeamAuditTrail;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.studyData && changes.studyData.currentValue) {
            this.loading = false;
            const { items, recordCount } = changes.studyData.currentValue;
            this.studies = items;
            this.pagination.totalItems = recordCount;
            this.selectedStudyId = undefined;
        }
    }

    select(study: Study): void {
        this.selectedStudyId = this.selectedStudyId === study.id ? undefined : study.id;
    }

    toggleActions($event, study: Study): void {
        $event.preventDefault();
        if (this.selectedStudyId !== study.id) {
            this.select(study);
        }
    }

    changePage({ page }): void {
        this.pagination.pageNum = page;
        this.pageUpdated.emit({
            ...this.pagination,
            searchField: 'uniqueProtocolId',
            searchTerm: this.searchInput
        });
    }

    changeSort(): void {
        const currentDir = this.pagination.sortDir;
        this.pagination.sortDir = currentDir === 'ASC' ? 'DESC' : 'ASC';
        this.pageUpdated.emit({
            ...this.pagination,
            searchField: 'uniqueProtocolId',
            searchTerm: this.searchInput
        });
    }

    onFilterChanged(searchText: string) {
        this.searchInput = searchText;
        this.pagination = {
            ...this.pagination,
            pageNum: 1
        };
        this.filterUpdated.emit({
            ...this.pagination,
            searchField: 'uniqueProtocolId',
            searchTerm: this.searchInput
        });
    }

    openAuditTrail(): void {
        if (!this.canViewAudits) {
            return;
        }
        const auditItem: AuditTrailModalItem = {
            id: this._currentTeam.id,
            teamId: this._currentTeam.teamId,
            permissions: this._currentTeam.permissions,
            type: this._currentTeam.type as AuditTrailTypes,
            name: this._currentTeam.name

        };

        const params = {
            subject: AuditTrailSubject.STUDY_PROFILES,
            teamId: auditItem.teamId,
            objectId: auditItem.id,
            overwrittenObjectId: null,
            limitToOverwritten: false,
            ...this.auditTrails.auditPagination
        };

        this.auditTrails.getAudits(params).pipe(
            tap((audits: CursorPaginationResponse<AuditTrail>) => {
                this.openAuditTrailsModal(audits, auditItem);
            }),
            catchError((error) => {
                this.apiErrors.handleError(error);
                return of(null);
            })
        ).subscribe();
    }

    private openAuditTrailsModal(audits, auditTrailParams): void {

        this.modals.show(AuditTrailModalComponent, {
            class: 'modal-lg',
            initialState: {
                data: audits,
                item: auditTrailParams,
                subject: AuditTrailSubject.STUDY_PROFILES,
                pagination: this.auditTrails.auditPagination,
                onPageChange: this.auditTrails.getAudits.bind(this.auditTrails)
            }
        });
    }
}
