
import { Ng2StateDeclaration } from '@uirouter/angular';
import { StudyManagerRouteGuard } from './study-manager-route-guard';
import { StudyManagerComponent } from './containers/study-manager/study-manager.component';
import { CreateStudyComponent } from './containers/create-study/create-study.component';
import { UpdateStudyComponent } from './containers/update-study/update-study.component';

const resolve = [
    {
        token: 'checkStudyStartupPermissions',
        deps: [StudyManagerRouteGuard],
        resolveFn: (guard: StudyManagerRouteGuard) => guard.checkStudyStartupPermissions().subscribe()
    }
];

const commonData = { requiresAuth: true };


export const studyManagerRoutes: Ng2StateDeclaration [] = [
    {
        name: 'app.team.study-manager',
        url: '/study-manager?&sortBy&sortDir&pageNum&filterBy&type',
        params: {
            sortBy: { dynamic: true },
            sortDir: { dynamic: true },
            pageNum: { dynamic: true },
            filterBy: { dynamic: true },
            type: { dynamic: true }
        },
        component: StudyManagerComponent,
        data: commonData,
        resolve
    },
    {
        name: 'app.team.study-manager-create',
        url: '/study-manager/create-study',
        params: {
            teamConfig: null
        },
        component: CreateStudyComponent,
        data: commonData,
        resolve
    },
    {
        name: 'app.team.study-manager-update',
        url: '/study-manager/update-study',
        params: {
            teamConfig: null
        },
        component: UpdateStudyComponent,
        data: commonData,
        resolve
    }
];
