import { Injectable } from '@angular/core';
import { CurrentSessionService } from '@app/core/current-session.service';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { StateService } from '@uirouter/angular';
import { Observable, of, throwError } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class StudyStartupRouteGuard {
    constructor(
    private currentSession: CurrentSessionService,
    private stateService: StateService,
    private notifications: NotificationsService
    ) {}

    checkPermissions(): Observable<boolean> {
        const { permissions, id } = this.currentSession.getCurrentTeam();
        const hasAccess = permissions.manageTeam
      || (
          permissions.manageTeamStudyStartup
        || permissions.createTeamStudies
        || permissions.importTeamStudyStructureTemplate
        || permissions.downloadTeamStructureTemplates
        || permissions.deleteTeamStructureTemplates
      );

        if (!hasAccess) {
            const errorMsg = 'User does not have permissions to perform this action';
            this.notifications.error(errorMsg);
            this.stateService.go('app.team.binders', { teamId: id });
            return throwError(errorMsg);
        }
        return of(true);

    }
}
