import { Ng2StateDeclaration, Transition } from '@uirouter/angular';
import { ManageTeamMembersComponent } from './containers/manage-team-members/manage-team-members.component';
import { ManageTeamSettingsComponent } from './containers/manage-team-settings/manage-team-settings.component';
import { CurrentTeamResolver } from './current-team-resolver';
import { ManageTeamStudiesContainer } from './containers/manage-team-studies/manage-team-studies-container.component';

const commonData = { requiresAuth: true };

export const teamRoutes: Ng2StateDeclaration [] = [
    {
        name: 'app.team.manage-team-members',
        url: '/manage-team-members',
        component: ManageTeamMembersComponent,
        data: commonData
    },
    {
        name: 'app.team.manage-team-settings',
        url: '/manage-team-settings',
        component: ManageTeamSettingsComponent,
        resolve: [
            {
                token: 'currentTeam',
                deps: [CurrentTeamResolver, Transition],
                resolveFn: (resolver: CurrentTeamResolver, transition: Transition) => resolver.resolveCurrentTeam(transition)
            }
        ],
        data: commonData
    },
    {
        name: 'app.team.manage-team-labels',
        url: '/manage-team-settings/labels',
        component: ManageTeamSettingsComponent,
        data: {
            ...commonData,
            tab: 'labels'
        }
    },
    {
        name: 'app.team.manage-team-studies',
        url: '/manage-team-settings/studies',
        component: ManageTeamSettingsComponent,
        data: {
            ...commonData,
            tab: 'studies'
        }
    },
    {
        name: 'app.team.manage-studies-by-team',
        url: '/studies',
        component: ManageTeamStudiesContainer,
        data: {
            ...commonData
        }
    },
    {
        name: 'app.team.manage-team-sso',
        url: '/manage-team-settings/sso',
        component: ManageTeamSettingsComponent,
        data: {
            ...commonData,
            tab: 'sso'
        }
    },
    {
        name: 'app.team.manage-team-sip-links',
        url: '/manage-team-settings/sip-config',
        component: ManageTeamSettingsComponent,
        data: {
            ...commonData,
            tab: 'teamSipLinks'
        }
    },
    {
        name: 'app.team.manage-security',
        url: '/manage-team-settings/security',
        component: ManageTeamSettingsComponent,
        data: {
            ...commonData,
            tab: 'security'
        }
    },
    {
        name: 'app.team.manage-study-roles',
        url: '/manage-study-roles',
        component: ManageTeamSettingsComponent,
        data: {
            ...commonData,
            tab: 'manageStudyRoles'
        }
    },
    {
        name: 'app.select-team',
        url: '/select-team',
        resolve: [
            {
                token: 'currentTeam',
                deps: [CurrentTeamResolver, Transition],
                resolveFn: (resolver: CurrentTeamResolver) => resolver.redirectToSelectTeam()
            }
        ],
        data: commonData
    }
];
