import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { PwdAndConfirmationInputsComponent } from '@app/widgets/pwd-and-confirmation-inputs/pwd-and-confirmation-inputs.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { UIRouterModule } from '@uirouter/angular';
import { WidgetsModule } from '../../widgets/widgets.module';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { ResetPasswordInfoComponent } from './components/reset-password-info/reset-password-info.component';
import { UserProfileFormComponent } from './components/user-profile-form/user-profile-form.component';
import { UserPrivacyConsentComponent } from './components/user-privacy-consent/user-privacy-consent.component';
import { UserSecuritySettingsComponent } from './components/user-security-settings/user-security-settings.component';
import { UserProfileComponent } from './containers/user-profile/user-profile.component';
import { userProfileRoutes } from './users.routes';

@NgModule({
    imports: [
        UIRouterModule.forChild({ states: userProfileRoutes }),
        ReactiveFormsModule,
        CommonModule,
        WidgetsModule,
        TooltipModule.forRoot(),
        TabsModule
    ],
    providers: [
    ],
    declarations: [
        UserProfileFormComponent,
        ChangePasswordComponent,
        PwdAndConfirmationInputsComponent,
        ResetPasswordInfoComponent,
        UserPrivacyConsentComponent,
        UserSecuritySettingsComponent,
        UserProfileComponent
    ],
    entryComponents: [
        UserProfileFormComponent,
        ChangePasswordComponent,
        PwdAndConfirmationInputsComponent,
        ResetPasswordInfoComponent,
        UserPrivacyConsentComponent,
        UserSecuritySettingsComponent,
        UserProfileComponent
    ],
    exports: [
    ]
})
export class UsersModule {}
