import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Course } from '@app/shared/models/user-team-training.model';
import {
    SignatureQueueResponse,
    TaskQueueResponse,
    ActionBarConfig,
    ActionLinkParams,
    GetSignatureQueueParams,
    GetTaskQueueParams,
    LoadSignatureQueueParams,
    LoadTaskQueueParams
} from './action-bar.service.types';
import { UserTrainingService } from '../user-training/user-training.service';
import { ActionBarInternalLink, ThemeColors } from '../../widgets/action-bar-item/action-bar-item.types';

@Injectable()
export class ActionBarService {
    readonly url = {
        signatureQueue: (teamId: string): string => `/api/teams/${teamId}/signature-queue`,
        taskQueue: (teamId: string): string => `/api/teams/${teamId}/task-queue`
    }

    constructor(
        private $http: HttpClient,
        private userTrainingService: UserTrainingService
    ) {}

    private loadSignatureQueue(params: LoadSignatureQueueParams): Observable<SignatureQueueResponse> {
        const { teamId, documentId, signatureRequestId } = params;
        const uri = this.url.signatureQueue(teamId);
        if (!documentId) {
            return this.$http.get<SignatureQueueResponse>(uri);
        }
        return this.$http.get<SignatureQueueResponse>(uri, {
            params: {
                documentId,
                ...signatureRequestId && { signatureRequestId }
            }
        });
    }

    private loadTaskQueue(params: LoadTaskQueueParams): Observable<TaskQueueResponse> {
        const { teamId, documentId, taskId } = params;
        const uri = this.url.taskQueue(teamId);
        if (!taskId && !documentId) {
            return this.$http.get<TaskQueueResponse>(uri);
        }
        return this.$http.get<TaskQueueResponse>(uri, {
            params: {
                ...documentId && { documentId },
                ...taskId && { taskId }
            }
        });
    }

    getSignatureQueue(params: GetSignatureQueueParams): Observable<ActionBarConfig> {
        const {
            teamId, documentId, documentType, signatureRequestId
        } = params;
        return this.loadSignatureQueue({ teamId, documentId, signatureRequestId }).pipe(
            map((signatureQueue) => {
                const {
                    reportId, position, count, currentRequest, nextRequest
                } = signatureQueue;
                if (!count || (signatureQueue.count === 1 && signatureQueue.position === signatureQueue.count)) {
                    return undefined;
                }
                const { objectId: nextDocumentId, id: nextSignatureRequestId } = nextRequest;
                let remainingCount = position ? count - 1 : count;
                if (currentRequest && currentRequest.entryId) {
                    remainingCount += 1;
                }
                let descriptionTextHelper = '';
                if (documentType === 'log' && currentRequest) {
                    descriptionTextHelper = currentRequest.entryRow ? `Sign on Row ${currentRequest.entryRow}` : 'Sign on This Log';
                }
                return {
                    barOptions: {
                        descriptionText: 'My Signature Queue',
                        descriptionTextHelper,
                        descriptionLink: this.getReportLink(teamId, reportId),
                        actionText: position ? `Next (${position} of ${count})` : `Start Signing (${count})`,
                        actionLink: this.getActionLink({
                            teamId,
                            documentId: nextDocumentId,
                            signatureRequestId: nextSignatureRequestId,
                            taskId: null
                        }),
                        completeActionText: remainingCount
                            ? this.getSignatureCompletionActionText(remainingCount)
                            : ''
                    },
                    theme: ThemeColors.BLUE
                };
            })
        );
    }

    getSignatureCompletionActionText(remainingSignatureCount: number): string {
        const requestLabel = remainingSignatureCount === 1 ? 'request' : 'requests';
        return `You have ${remainingSignatureCount} other signature ${requestLabel} to sign. Ready to sign more?`;
    }

    getTaskQueue(params: GetTaskQueueParams): Observable<ActionBarConfig> {
        const { teamId, documentId, taskId } = params;
        return this.loadTaskQueue({ teamId, documentId, taskId }).pipe(
            map((taskQueue) => {
                const {
                    reportId, position, count, nextTask
                } = taskQueue;
                if (!count) {
                    return undefined;
                }
                const { id: nextTaskId, targetId: nextDocumentId } = nextTask;
                return {
                    barOptions: {
                        descriptionText: 'My Task Queue',
                        descriptionTextHelper: '',
                        descriptionLink: this.getReportLink(teamId, reportId),
                        actionText: position ? `Next (${position} of ${count})` : `Start Tasks (${count})`,
                        actionLink: this.getActionLink({
                            teamId,
                            documentId: nextDocumentId,
                            signatureRequestId: null,
                            taskId: nextTaskId
                        })
                    },
                    theme: ThemeColors.GREEN
                };
            })
        );
    }

    getTrainingQueueActionBarConfig$(): Observable<ActionBarConfig> {
        return this.userTrainingService.trainingQueue$.pipe(
            map((userTrainings) => {
                const numberOfIncompleteTrainings = userTrainings?.length;
                if (!numberOfIncompleteTrainings) {
                    return undefined;
                }

                let trainingRedirectUrl: string;
                const firstUncompletedTraining = userTrainings.find((training) => !training.trainingCompleted);
                if (firstUncompletedTraining?.primaryCourse?.urls?.length && !firstUncompletedTraining.primaryCourse.completed) {
                    trainingRedirectUrl = this.getCourseRedirectionUrl(firstUncompletedTraining.primaryCourse);
                }
                else {
                    const firstUncompletedCourse = firstUncompletedTraining.courses.required.find((course) => !course.completed)
                        || this.getFirstUncompletedORCourse(firstUncompletedTraining.courses.optional);
                    trainingRedirectUrl = firstUncompletedCourse
                        ? this.getCourseRedirectionUrl(firstUncompletedCourse) : this.userTrainingService.florenceTrainingBaseUrl;
                }

                return {
                    barOptions: {
                        descriptionText: 'My Training Queue',
                        descriptionLink: trainingRedirectUrl,
                        actionText: `Start Training (${numberOfIncompleteTrainings})`,
                        actionLink: trainingRedirectUrl
                    },
                    theme: ThemeColors.ORANGE
                };
            })
        );
    }

    private getFirstUncompletedORCourse(courses: Course[][]): Course {
        // Check where all courses are not completed in the subArray
        const incompleteSubArray = courses.find((subArray) => subArray.every((course) => !course.completed));
        return incompleteSubArray ? incompleteSubArray[0] : null;
    }

    private getCourseRedirectionUrl(course: Course): string {
        const { urls } = course;
        return urls.length === 1
            ? urls[0]
            : (urls.find((url) => url.startsWith(this.userTrainingService.florenceTrainingBaseUrl)) || urls[0]);
    }

    private getReportLink(teamId: string, reportId: string): ActionBarInternalLink {
        return {
            state: 'app.team.reports',
            params: {
                teamId,
                reportId
            }
        };
    }

    private getActionLink(params: ActionLinkParams): ActionBarInternalLink {
        const {
            teamId, documentId, signatureRequestId, taskId
        } = params;
        return {
            state: 'app.team.document-show-most-recent',
            params: {
                teamId,
                documentId,
                ...signatureRequestId && { signatureRequestId },
                ...taskId && { taskId }
            }
        };
    }
}
