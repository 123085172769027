import { Ng2StateDeclaration } from '@uirouter/angular';
import { DocumentSharingComponent } from './containers/document-sharing/document-sharing.component';

const commonData = { requiresAuth: true };

export const documentSharingRoutes: Ng2StateDeclaration [] = [
    {
        name: 'app.team.document-sharing',
        url: '/document-sharing',
        component: DocumentSharingComponent,
        data: commonData
    }
];
