import 'core-js/stable';
import '!!file-loader?name=[name].[ext]!../favicon.ico'; // eslint-disable-line import/no-webpack-loader-syntax, import/no-unresolved
import '../stylesheets/styles.scss';

import 'zone.js';
import 'reflect-metadata';
import * as angular from 'angular';

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { setAngularJSGlobal, UpgradeModule } from '@angular/upgrade/static';
import { UIRouter, UrlService } from '@uirouter/core';
import '@uirouter/angularjs';

import { AppModule } from './app.module';
import AppJsModule from './app.module.ajs';

setAngularJSGlobal(angular);

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .then((platformRef) => {
        const { injector } = platformRef;
        const upgrade = injector.get(UpgradeModule) as UpgradeModule;

        upgrade.bootstrap(document.body, [AppJsModule.name], { strictDi: true });
        const url: UrlService = injector.get(UIRouter).urlService;

        // Fallback for old (non-existing) /manage-team-profile
        url.rules.when(
            '/app/teams/:teamId/manage-team-profile',
            '/app/teams/:teamId/manage-team-settings'
        );


        url.listen();
        url.sync();
    });
