import { Ng2StateDeclaration } from '@uirouter/angular';
import { AnnouncementsInboxComponent } from './containers/announcements-inbox/announcements-inbox.component';
import { AnnouncementViewComponent } from './containers/announcement-view/announcement-view.component';


const commonData = { requiresAuth: true };


export const announcementsRoutes: Ng2StateDeclaration [] = [
    {
        name: 'app.team.announcements',
        url: '/announcements?&sortBy&sortDir&pageNum&filterBy&type',
        params: {
            // make params dynamic, do not reload state when some of them change
            sortBy: { dynamic: true },
            sortDir: { dynamic: true },
            pageNum: { dynamic: true },
            filterBy: { dynamic: true },
            type: { dynamic: true }
        },
        component: AnnouncementsInboxComponent,
        data: commonData
    },

    {
        name: 'app.team.announcement-view',
        url: '/announcements/:announcementId',
        params: { returnTo: null },
        component: AnnouncementViewComponent,
        data: commonData
    }
];
