import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EntityModule } from '@app/widgets/entity/entity.module';
import { WidgetsModule } from '@app/widgets/widgets.module';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ScrollingModule, ScrollDispatcher, ViewportRuler } from '@angular/cdk/scrolling';
import { SharedModule } from '@app/shared/shared.module';
import { UIRouterModule } from '@uirouter/angular';
import { BinderFormComponent } from './components/binder-form/binder-form.component';
import { DestroyBinderComponent } from './components/destroy-binder/destroy-binder.component';
import { BinderDownloadComponent } from './containers/binder-download/binder-download.component';
import { BindersIndexComponent } from './containers/binders-index/binders-index.component';
import { bindersRoutes } from './binders.routes';

@NgModule({
    imports: [
        UIRouterModule.forChild({ states: bindersRoutes }),
        BrowserModule,
        BrowserAnimationsModule,
        TooltipModule.forRoot(),
        BsDropdownModule.forRoot(),
        CommonModule,
        WidgetsModule,
        ScrollingModule,
        EntityModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule
    ],
    providers: [
        ScrollDispatcher,
        ViewportRuler
    ],
    declarations: [
        BinderDownloadComponent,
        DestroyBinderComponent,
        BinderFormComponent,
        BindersIndexComponent
    ],
    entryComponents: [
        BinderDownloadComponent,
        DestroyBinderComponent,
        BinderFormComponent,
        BindersIndexComponent
    ],
    exports: []
})
export class BindersModule { }
