import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { SharedModule } from '@app/shared/shared.module';
import { WidgetsModule } from '@app/widgets/widgets.module';
import { UIRouterModule } from '@uirouter/angular';
import { PermissionReportComponent } from './containers/permission-report/permission-report.component';
import { PermissionReportTreeComponent } from './components/permission-report-tree/permission-report-tree.component';
import { DownloadPermissionReportComponent } from './components/download-permission-report/download-permission-report.component';
import { PermissionTreePermissionComponent } from './components/permission-tree-permission/permission-tree-permission.component';
import { PermissionsEditComponent } from './containers/permissions-edit/permissions-edit.component';
import { PermissionInfoModalComponent } from './containers/permission-info-modal/permission-info-modal.component';
import { permissionsRoute } from './permissions.routes';

@NgModule({
    imports: [
        BrowserModule,
        UIRouterModule.forChild({ states: permissionsRoute }),
        BrowserAnimationsModule,
        FormsModule,
        ScrollingModule,
        BsDropdownModule.forRoot(),
        TooltipModule.forRoot(),
        CommonModule,
        SharedModule,
        WidgetsModule
    ],
    providers: [
        { provide: Window, useValue: window }
    ],
    declarations: [
        PermissionReportComponent,
        PermissionReportTreeComponent,
        DownloadPermissionReportComponent,
        PermissionTreePermissionComponent,
        PermissionsEditComponent,
        PermissionInfoModalComponent
    ],
    exports: [
        PermissionReportComponent,
        PermissionReportTreeComponent,
        DownloadPermissionReportComponent,
        PermissionTreePermissionComponent,
        PermissionsEditComponent,
        PermissionInfoModalComponent

    ],
    entryComponents: [
        PermissionReportComponent,
        PermissionReportTreeComponent,
        DownloadPermissionReportComponent,
        PermissionTreePermissionComponent,
        PermissionsEditComponent,
        PermissionInfoModalComponent

    ]
})
export class PermissionsModule { }
