
import { Ng2StateDeclaration } from '@uirouter/angular';
import { PermissionReportComponent } from './containers/permission-report/permission-report.component';

const commonData = { requiresAuth: true };


export const permissionsRoute: Ng2StateDeclaration [] = [
    {
        name: 'app.team.permission-report',
        url: '/permission-report/:subjectId/:subjectType',
        params: {
            filter: null, // PermissionReportFilter.ALL.id,
            subjectId: null,
            subjectType: 'user'
        },
        component: PermissionReportComponent,
        data: commonData
    }
];
