import { Ng2StateDeclaration } from '@uirouter/angular';
import { GlobalViewComponent } from './containers/global-view/global-view.component';


const commonData = { requiresAuth: true };


export const globalViewRoutes: Ng2StateDeclaration [] = [
    {
        name: 'app.team.global-view',
        url: '/global-view/:objectId/:objectType/:objectName',
        params: {
            objectId: '',
            objectType: '',
            objectName: '',
            isRootReselected: false
        },
        component: GlobalViewComponent,
        data: commonData
    }
];
