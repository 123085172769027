import * as _ from 'lodash';
import {
    ApiErrorResponse, BatchResponseItem, CloneDestination, CloneInstruction, Document, StudyResponsibilities, StudyRole,
    DocumentId, User
} from '@app/shared/models';
import {
    HttpClient,
    HttpErrorResponse,
    HttpParams,
    HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { StateService } from '@uirouter/core';
import {
    catchError,
    map,
    switchMap,
    tap
} from 'rxjs/operators';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { SessionActivityService } from '@app/components/sessions/session-activity.service';
import * as BrowserMd5File from 'browser-md5-file';
import { BulkSetDatesUpdate } from '@app/components/documents/components/document-bulk-set-dates/document-bulk-set-dates.component.types';
import { GlobalViewItemDecoration } from '@app/components/global-view/components/global-view-item/global-view-item.types';
import { VirtualTreeFlatNode } from '@app/widgets/virtual-tree/virtual-tree.component.types';
import { DocumentSignData } from '@app/components/documents/components/sign-document/sign-document.component.types';
import { DocumentDeclineData } from '@app/components/documents/containers/document-decline/document-decline.component.types';
import {
    FillPlaceholderLogParams,
    DocumentActionResponse,
    GetSignatureStatusResponse,
    NavParams, NavParamsInput,
    AnnotationSavedParams,
    ShowDocumentSavedNotificationParams,
    DocumentFormSavedParams,
    DocumentsCloneBatchParams,
    DocumentClone,
    LogMetadata,
    DocumentUploadResponse,
    DocumentCustomCloneParams,
    DocumentMoveResponse,
    GetJobTitleRequiredResponse,
    CreateLogParams,
    DestroyDocuments,
    DocumentUpdateResponse,
    BulkUpdateDueAndExpirationDatesResponseItem,
    UpdateDocumentIsLockedItem,
    SetPiiResponse,
    GetDocumentsPendingSignatureRequestsResponse,
    SignatureRequestsBulkUpdatePayload,
    SignatureRequestsBulkUpdateResponse,
    GetNoSignPermissionsParams,
    GetNoSignPermissionsResponse,
    GetPotentialSignersParams,
    DocumentApprovalRejectResponse,
    SendToSipParams,
    UpdateNameResponse,
    UpdateDocumentCategoriesParams,
    DocumentPlaceholderFillModes,
    PlaceholderFillParams,
    LogDocumentCreateResponse
} from './documents.service.types';
import { LogTemplateUtil } from '../../components/log-templates/utils/log-template.util';

@Injectable()
export class DocumentService {

    readonly customErrorMessages = 'There was an unexpected error. Please try again. If the error still happens, please contact your administrator.'
    readonly url = {
        base: '/api/documents',
        annotations: _.template('/api/documents/<%= id %>/versions/<%= version %>/annotations'),
        formFields: _.template('/api/documents/<%= id %>/versions/<%= version %>/formFields'),
        single: _.template('/api/documents/<%= id %>'),
        multiple: _.template('/api/documents'),
        singleWithoutVersion: _.template('/api/documents/<%= id %>'),
        singleWithVersion: _.template('/api/documents/<%= id %>/versions/<%= version %>'),
        signatures: ({ id, version }) => `/api/documents/${id}/versions/${version}/signatures`,
        signatureRequests: _.template('/api/documents/<%= id %>/signature-requests'),
        tasks: _.template('/api/documents/<%= id %>/tasks'),
        timelines: _.template('/api/documents/<%= id %>/timelines'),
        poll: _.template('/api/documents/<%= id %>/versions/<%= version %>/showConversionStatus'),
        documentProperties: _.template('/api/documents/<%= id %>/documentProperties'),
        fillPlaceholder: _.template('/api/placeholders/<%= id %>'),
        fillPlaceholderWithNewLog: _.template('/api/placeholders/<%= id %>/fill-new-log'),
        logs: '/api/documents/logs',
        logSingle: (documentId: string): string => `/api/documents/logs/${documentId}`,
        doaLogSingle: (documentId: string): string => `/api/documents/logs/${documentId}/doa`,
        cloneBatch: (): string => '/api/documents/clone',
        customClone: (documentId: string): string => `/api/documents/${documentId}/clone`,
        sendToSip: (docId: string, version: number): string => `/api/documents/${docId}/versions/${version}/send-to-sip`,
        potentialSigners: (teamId: string): string => `/api/teams/${teamId}/documents/potential-signers`,
        bulkSignatureRequests: '/api/documents/signature-requests',
        noSignPermissions: (teamId: string): string => `/api/teams/${teamId}/documents/no-sign-permissions`,
        jobTitleRequired: (documentId: string): string => `/api/documents/${documentId}/job-title-required`,
        updateDocumentIsLocked: '/api/documents/is-locked',
        bulkUpdateDueAndExpirationDates: '/api/documents/dates'
    };

    constructor(
        private $state: StateService,
        private http: HttpClient,
        private Notifications: NotificationsService,
        private SessionActivity: SessionActivityService
    ) {}


    isShortcut(doc: Document): boolean {
        return doc && doc.subType === 'shortcut';
    }

    getCurrentDocDisplayVersion(doc: Document): number {
        return doc.originalDocumentVersion || doc.version || 1;
    }

    getDocumentSignatureStatus(params): Observable<GetSignatureStatusResponse | HttpErrorResponse> {
        const {
            documentId,
            version,
            contentVersion = undefined,
            includePreviousSignatures = false,
            includeLogEntrySignatureRequests = false,
            includePotentialSigners = false
        } = params;
        const query = {
            contentVersion: contentVersion || version,
            includePreviousSignatures,
            includeLogEntrySignatureRequests,
            includePotentialSigners
        };

        return this.http.get<GetSignatureStatusResponse>(this.url.signatures({ id: documentId, version }), { params: query })
            .pipe(map((response) => response as GetSignatureStatusResponse),
                catchError((error) => this.handleAndThrowError(error)));
    }

    fillPlaceholderWithNewLog(params: FillPlaceholderLogParams): Observable<DocumentActionResponse | HttpErrorResponse> {
        const { id } = params;
        return this.http.patch<DocumentActionResponse>(this.url.fillPlaceholderWithNewLog({ id }), params)
            .pipe(
                tap((response) => {
                    const { document } = response;
                    this.goToDocument({ doc: document });
                }),
                catchError((error) => this.handleAndThrowError(error))
            );
    }

    fillPlaceholder(params: PlaceholderFillParams): Observable<DocumentActionResponse | HttpErrorResponse> {
        const {
            id, originalDocumentId, fillMode, name
        } = params;
        return this.http.patch<DocumentActionResponse>(
            this.url.fillPlaceholder({ id }), { originalDocumentId, fillMode, name }
        ).pipe(
            tap((response) => {
                const { document } = response;
                if (
                    fillMode === DocumentPlaceholderFillModes.MOVE
                    || fillMode === DocumentPlaceholderFillModes.SHORTCUT
                ) {
                    return this.goToDocument({ doc: document });
                }
                return this.$state.go(this.$state.current, {}, { reload: true });
            }),
            catchError((error) => this.handleAndThrowError(error))
        );
    }

    goToDocument(params: NavParamsInput): void {
        const navParams = this.getNavParams(params);
        this.$state.go('app.team.document-show', navParams, { reload: params.reload ? params.reload : false });
    }

    getDocumentsPendingSignatureRequests(documentIds: string[]):Observable<GetDocumentsPendingSignatureRequestsResponse> {
        const uri = this.url.bulkSignatureRequests;
        const params = new HttpParams().set('documentIds', documentIds.join(','));

        return this.http.get<GetDocumentsPendingSignatureRequestsResponse>(uri, { params })
            .pipe(
                map((response) => {
                    return response;
                }),
                catchError((err) => {
                    const { error: { message } } = err;
                    this.Notifications.error(message || this.customErrorMessages);
                    return throwError(err);
                })
            );
    }

    signatureRequestsBulkUpdate(
        payload: SignatureRequestsBulkUpdatePayload
    ): Observable<SignatureRequestsBulkUpdateResponse | HttpErrorResponse> {
        const uri = this.url.bulkSignatureRequests;

        return this.http.patch<SignatureRequestsBulkUpdateResponse>(uri, payload)
            .pipe(
                tap((data) => {
                    let failedCount = 0;
                    let createdCount = 0;
                    let updatedCount = 0;
                    let canceledCount = 0;
                    data.forEach((r) => {
                        if (r.statusCode === 200) {
                            createdCount += r.payload.createdRequests.length;
                            updatedCount += r.payload.updatedRequests.length;
                            canceledCount += r.payload.cancelledRequestCount;
                        }
                        else {
                            failedCount += 1;
                        }
                    });

                    const segments = [
                        createdCount && `${createdCount} requested`,
                        updatedCount && `${updatedCount} reminded`,
                        canceledCount && `${canceledCount} canceled`
                    ].filter(Boolean).join(', ');
                    if (createdCount || updatedCount || canceledCount) {
                        this.Notifications.success(`Document signatures: ${segments}`);
                    }
                    if (failedCount) {
                        this.Notifications.error(`Document signatures: ${failedCount} failed`);
                    }
                }),
                catchError((err) => this.handleAndThrowError(err))
            );
    }

    sendToSip(params: SendToSipParams): Observable<void | HttpErrorResponse> {
        const {
            docId, version, studyId, siteId
        } = params;

        return this.http
            .post(this.url.sendToSip(docId, version), { studyId, siteId })
            .pipe(
                map(() => {
                    this.Notifications.info({ message: 'Document export to SIP in progress.', closeOnClick: false });
                }), catchError((error) => this.handleAndThrowError(error))
            );
    }

    approve(doc: Document): Observable<Document | HttpErrorResponse> {
        return this.http
            .post<DocumentApprovalRejectResponse>(this.url.documentProperties({ id: doc.id }), { documentProperties: { approveStatus: 'approved' } })
            .pipe(
                map((response) => {
                    doc.documentProperties = response.updatedDocumentProperty;
                    this.Notifications.success(`${doc.filename} approved.`);
                    return doc;
                }), catchError((error) => this.handleAndThrowError(error))
            );
    }

    reject(doc: Document, reason: string): Observable<Document | HttpErrorResponse> {
        const approveStatus = 'rejected';
        return this.http
            .post<DocumentApprovalRejectResponse>(
                this.url.documentProperties({ id: doc.id }),
                { documentProperties: { approveStatus, reason } }
            )
            .pipe(
                map((response) => {
                    doc.documentProperties = response.updatedDocumentProperty;
                    this.Notifications.success(`${doc.filename} rejected.`);
                    return doc;
                }), catchError((error) => this.handleAndThrowError(error))
            );
    }

    getPotentialSigners(params: GetPotentialSignersParams): Observable<User[] | HttpErrorResponse> {
        const { teamId, filter, documentIds } = params;
        const loadUrl = this.url.potentialSigners(teamId);

        const getParams = {
            documentIds: JSON.stringify(documentIds),
            ...(filter && { filter })
        };
        return this.http.get<User[]>(loadUrl, { params: getParams })
            .pipe(
                map((response) => {
                    return response;
                }),
                catchError((error) => this.handleAndThrowError(error))
            );
    }

    getNoSignPermissions(params: GetNoSignPermissionsParams): Observable<GetNoSignPermissionsResponse | HttpErrorResponse> {
        const uri = this.url.noSignPermissions(params.teamId);
        const queryParams = new HttpParams()
            .set('documentIds', JSON.stringify(params.documentIds))
            .set('userIds', JSON.stringify(params.userIds));
        return this.http.get<GetNoSignPermissionsResponse>(uri, { params: queryParams })
            .pipe(
                map((response) => response),
                catchError((error) => this.handleAndThrowError(error))
            );
    }

    getDocRequestVersion(doc: Document, explicitVersion: number): number | string {
        const docVersion = typeof doc.id === 'object' && 'version' in doc.id ? doc.id.version : doc.version;
        if (doc.subType === 'shortcut' || docVersion === 0 || docVersion === 0) {
            return 0;
        }
        return explicitVersion || docVersion;
    }

    getDocumentJobTitleRequired(documentId: string): Observable<GetJobTitleRequiredResponse | HttpErrorResponse> {
        const uri = this.url.jobTitleRequired(documentId);

        return this.http.get<GetJobTitleRequiredResponse>(uri)
            .pipe(
                map((response) => {
                    return response;
                }),
                catchError((err) => this.handleAndThrowError(err))
            );
    }

    load(id: string, version: string, contentVersion: string): Observable<Document> {
        let params = new HttpParams();
        if (contentVersion && contentVersion !== version) {
            params = params.set('contentVersion', contentVersion);
        }

        const loadUrl = version ? this.url.singleWithVersion({ id, version }) : this.url.singleWithoutVersion({ id });

        return this.http.get<{ document: Document }>(loadUrl, { params })
            .pipe(
                map(({ document }) => document),
                catchError((error: HttpErrorResponse) => {
                    const isPermErr = error.status === 403 && error.error.message === 'User does not have permissions to perform this action';
                    const msg = isPermErr && 'User does not have permissions to view this Document.';

                    this.Notifications.error(msg || this.customErrorMessages);
                    return throwError(error);
                })
            );
    }

    destroy(docs: Document[], reason: string): Observable<void| HttpErrorResponse> {
        const ids = docs.map(({ id }) => id);
        const params = new HttpParams().set('reason', reason).set('ids', ids.join(','));

        return this.http.delete<DestroyDocuments>(this.url.multiple(), { params })
            .pipe(
                map(({ documents, errors }) => {
                    documents.forEach((doc) => {
                        const msg = `${doc.title} deleted.`;
                        this.Notifications.success(msg);
                    });

                    if (errors.length > 0) {
                        errors.forEach((error) => {
                            const rawMsg = error.error.message;
                            const fullMessage = `An error occurred for ${error.document.title}. ${rawMsg}`;
                            this.Notifications.error(fullMessage || this.customErrorMessages);
                        });
                    }
                })
            );
    }

    setHasPii(documents: Document[], hasPii: boolean): Observable<SetPiiResponse> {
        const payload = {
            documentIds: documents.map((item) => item.id),
            hasPii
        };
        return this.http.patch<SetPiiResponse>(this.url.multiple(), payload);
    }

    bulkUpdateDueAndExpirationDates(params: BulkSetDatesUpdate[]): Observable<BulkUpdateDueAndExpirationDatesResponseItem[]> {
        const uri = this.url.bulkUpdateDueAndExpirationDates;
        return this.http.patch<BulkUpdateDueAndExpirationDatesResponseItem[]>(uri, { documents: params });
    }

    updateDocumentIsLocked(params: UpdateDocumentIsLockedItem[]): Observable<UpdateDocumentIsLockedItem[]> {
        const uri = this.url.updateDocumentIsLocked;
        return this.http.patch<UpdateDocumentIsLockedItem[]>(uri, { docsArray: params }).pipe(
            catchError((err) => {
                return throwError(err);
            })
        );
    }

    getSignatureRequests(documentId: string): Observable<GlobalViewItemDecoration> {
        const uri = this.url.signatureRequests({ id: documentId });
        return this.http.get<GlobalViewItemDecoration>(uri).pipe(
            map((data) => data),
            catchError((err) => {
                return throwError(err);
            })
        );
    }

    getTimelines(documentId: string): Observable<GlobalViewItemDecoration> {
        const uri = this.url.timelines({ id: documentId });
        return this.http.get<GlobalViewItemDecoration>(uri).pipe(
            map((data) => data),
            catchError((err) => {
                return throwError(err);
            })
        );
    }


    getTasks(documentId: string): Observable<GlobalViewItemDecoration> {
        const uri = this.url.tasks({ id: documentId });
        return this.http.get<GlobalViewItemDecoration>(uri).pipe(
            map((data) => data),
            catchError((err) => {
                return throwError(err);
            })
        );
    }

    updateName(documentId: string, name: string, renameShortcuts: boolean): Observable<UpdateNameResponse | HttpErrorResponse> {
        return this.http.patch<DocumentUpdateResponse>(this.url.single({ id: documentId }), { name, renameShortcuts }).pipe(
            map((response) => {
                const { errors, documents, renamedShortcuts } = response;
                if (errors.length > 0) {
                    this.Notifications.error(errors[0].error.message || this.customErrorMessages);
                }
                this.Notifications.success(`Document name updated to "${documents[0].name}".`);
                const result = {
                    document: documents[0],
                    renamedShortcuts: renamedShortcuts[0]
                };
                return result;

            }),
            catchError((error) => this.handleAndThrowError(error))
        );
    }

    updateDocumentCategory(
        documentId: string | DocumentId,
        documentCategory: UpdateDocumentCategoriesParams
    ): Observable<Document | HttpErrorResponse> {
        return this.http.patch<DocumentUpdateResponse>(this.url.single({ id: documentId }), { documentCategory }).pipe(
            map((response) => {
                const { errors } = response;
                if (errors.length > 0) {
                    throw response.errors[0];
                }

                const message = documentCategory.statusId ? 'Document type and status updated.' : 'Document type updated.';
                this.Notifications.success(message);
                return response.documents[0];
            }),
            catchError((error) => this.handleAndThrowError(error))
        );
    }

    createLog(params: CreateLogParams): Observable<Document | HttpErrorResponse> {
        return this.http.post<LogDocumentCreateResponse>(this.url.logs, params, { observe: 'response' })
            .pipe(
                tap((httpResponse) => {
                    if (httpResponse.status === 207) {
                        this.Notifications.warning(httpResponse.body.message);
                        return;
                    }
                    this.Notifications.success(`${params.filename} Log document created.`);
                }),
                map(({ body }) => body.document),
                catchError((error: HttpErrorResponse) => this.handleAndThrowError(error))
            );
    }

    replace(doc: Document, file: File, reason?: string, clearExpiration?: boolean, title?: string,
        renameShortcuts?: boolean): Observable<DocumentActionResponse> {
        const formData: FormData = new FormData();
        formData.append('file', file, file.name);
        formData.append('filename', file.name);
        formData.append('comment', reason || '');
        formData.append('keepExpiration', `${!clearExpiration}`);
        if (title) {
            formData.append('title', title);
            formData.append('renameShortcuts', renameShortcuts ? 'true' : 'false');
        }

        return this.http.post<DocumentActionResponse>(this.url.single({ id: doc.id }), formData).pipe(
            tap((response) => {
                const { document } = response;

                const recordData = {
                    when: new Date(Date.now()),
                    document
                };
                this.SessionActivity.pushSuccessfulDocumentUpload(doc.teamId, recordData);

                if (document.version > 1) {
                    this.goToDocument({ doc: document });
                }
                else {
                    window.location.reload();
                }
            }),
            catchError((error: HttpErrorResponse) => {
                this.documentUploadErrorNotification(error, file);
                const recordData = {
                    when: new Date(Date.now()),
                    document: _.assign({}, doc, {
                        teamId: doc.teamId,
                        binderId: doc.binderId,
                        folderId: doc.folderId,
                        type: 'document',
                        subType: 'content',
                        name: file.name,
                        filename: file.name,
                        comment: reason,
                        file: {
                            size: file.size
                        }
                    })
                };
                this.SessionActivity.pushFailedDocumentUpload(doc.teamId, recordData);
                return throwError(error);
            })
        );
    }

    customClone(
        document: Document,
        destination: CloneDestination,
        cloneInstructions: CloneInstruction[]
    ): Observable<void | HttpErrorResponse> {

        const payload: DocumentCustomCloneParams = {
            destination,
            cloneInstructions
        };
        const documentId = typeof document.id === 'object' ? document.id.documentId : document.id;

        return this.http.post<BatchResponseItem<DocumentClone>[]>(this.url.customClone(documentId), payload)
            .pipe(
                map((data) => {
                    const cloneResponseItems = data.filter((responseItem) => responseItem.statusCode === 200);
                    if (cloneResponseItems.length === 1) {
                        const clone = cloneResponseItems[0].payload as DocumentClone;
                        const msg = '<%= docName %> successfully duplicated.<br>'
                  + '<strong><a href="<%= url %>">GO TO DOCUMENT</a></strong>';
                        const msgTemplate = _.template(msg);
                        const lastVersion = _.last(clone.versions).number;
                        const navParams = this.getNavParams({ doc: clone, version: lastVersion });
                        const params = {
                            docName: document.name,
                            url: this.$state.href('app.team.document-show', navParams)
                        };
                        this.Notifications.success(msgTemplate(params));
                    }
                    else if (cloneResponseItems.length > 1) {
                        this.Notifications.success(`${document.title} successfully duplicated ${cloneResponseItems.length} time(s).`);
                    }
                    else {
                        const rawMsg = (data[0]?.payload as ApiErrorResponse)?.message;
                        const fullMessage = `An error occurred for ${document.title}. ${rawMsg}`;
                        this.Notifications.error(fullMessage || this.customErrorMessages);
                    }
                }),
                catchError((error) => this.handleAndThrowError(error))
            );
    }

    moveTo(
        destination: VirtualTreeFlatNode,
        itemsBeingMoved: Document[]
    ): Observable<void | DocumentMoveResponse| HttpErrorResponse> {
        const payload = {
            documentIds: itemsBeingMoved.map((item) => item.id),
            newBinderId: destination.binderId || destination.id,
            ...(destination.type !== 'binder') && { newFolderId: destination.id }
        };

        return this.http.patch<DocumentMoveResponse>(this.url.multiple(), payload) // Remove the void type from the return type
            .pipe(
                map((response: DocumentMoveResponse) => {
                    const { documents, errors } = response;

                    if (errors.length > 0) {
                        errors.forEach((error) => {
                            const rawMsg = error.error.message;
                            const fullMessage = `An error occurred for ${error.document.title}. ${rawMsg}`;
                            this.Notifications.error(fullMessage || this.customErrorMessages);
                        });
                    }

                    documents.forEach((doc: Document) => {
                        this.showDocumentMovedNotification(doc);
                    });
                }),
                catchError((error: HttpErrorResponse) => this.handleAndThrowError(error))
            );
    }

    showDocumentMovedNotification(doc: Document, version?: number): void {
        const msg = `<%= docName %> successfully moved.<br>
            <strong><a href="<%= url %>">GO TO <%= docType %> </a></strong>`;
        const lastVersion = version || (_.last(doc.versions)).number;
        const msgTemplate = _.template(msg);
        const navParams = this.getNavParams({ doc, version: lastVersion });
        const params = {
            docName: doc.name,
            url: this.$state.href('app.team.document-show', navParams),
            docType: doc.subType === 'content' ? 'document' : doc.subType
        };
        this.Notifications.success(msgTemplate(params));
    }


    createDocumentApiRequest(
        formData: FormData,
        fileMd5Hash: BrowserMd5File
    ): Observable <HttpResponse<DocumentUploadResponse>> {

        return this.http.post<DocumentUploadResponse>(this.url.base, formData, {
            headers: {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                'Content-MD5': fileMd5Hash
            },
            observe: 'response'
        });
    }

    create(teamId: string, binderId: string, folderId: string, file: File): Observable<Document> {

        const recordData = {
            when: new Date(Date.now()),
            document: {
                teamId,
                binderId,
                folderId,
                type: 'document',
                subType: 'content',
                name: file.name,
                filename: file.name,
                file: {
                    size: file.size
                }
            } as unknown as Document
        };

        if (!file.size) {
            const errorMessages = 'File size is zero';
            this.Notifications.error(errorMessages);
            this.SessionActivity.pushFailedDocumentUpload(teamId, recordData as any);
            return throwError(errorMessages);
        }

        const formData: FormData = new FormData();
        formData.append('teamId', teamId);
        formData.append('binderId', binderId);
        formData.append('file', file, file.name);
        formData.append('filename', file.name);
        formData.append('size', file.size.toString());
        if (folderId) {
            formData.append('folderId', folderId);
        }

        const browserFileHash = new BrowserMd5File();

        return new Observable<string>((observer) => {
            browserFileHash.md5(file, (error, fileMd5Hash) => {
                if (error) {
                    observer.error(error);
                }
                else {
                    observer.next(fileMd5Hash);
                    observer.complete();
                }
            });
        }).pipe(
            switchMap((fileMd5Hash) => this.createDocumentApiRequest(formData, fileMd5Hash)),
            map((response) => {

                const { document } = response.body;
                if (document) {
                    this.Notifications.success(`${file.name} imported.`);
                    this.SessionActivity.pushSuccessfulDocumentUpload(teamId, {
                        when: new Date(Date.now()),
                        document
                    });
                }
                return document;
            }),
            catchError((error: HttpErrorResponse) => {
                this.documentUploadErrorNotification(error.error, file);
                this.SessionActivity.pushFailedDocumentUpload(teamId, recordData as any);
                return throwError(error);
            })
        );
    }

    saveAnnotations({
        documentId, version, annotations, email, reason, password, signingPasscode, jobTitle
    }:AnnotationSavedParams): Observable<DocumentActionResponse> {
        return this.http
            .post<DocumentActionResponse>(this.url.annotations({ id: documentId, version }), {
                annotations, email, reason, password, signingPasscode, jobTitle
            })
            .pipe(
                tap((response) => {
                    const { document } = response;
                    this.showDocumentSavedNotification({ annotations });
                    this.goToDocument({ doc: document });
                })
            );
    }

    saveFormFields({
        documentId, version, formFields, email, password, shouldFinalize, signingPasscode, jobTitle
    }: DocumentFormSavedParams): Observable<DocumentActionResponse | HttpErrorResponse> {

        let errorOccurred = false;
        let isFinished = false;
        let message = 'Saving draft, this may take a minute.';
        if (shouldFinalize) {
            message = 'Finalizing document, this may take a minute.';
        }
        setTimeout(() => {
            if (!errorOccurred && !isFinished) {
                this.Notifications.info(message);
            }
        }, 500);

        const params = {
            formFields, email, password, shouldFinalize, signingPasscode, jobTitle
        };
        return this.http.patch<DocumentActionResponse>(this.url.formFields({ id: documentId, version }), params).pipe(
            tap((response) => {
                isFinished = true;
                const { document: doc } = response;

                this.showDocumentSavedNotification({ formFields, shouldFinalize });
                this.goToDocument({ doc });
            }), catchError((error: HttpErrorResponse) => {
                errorOccurred = true;
                return this.handleAndThrowError(error);
            })
        );
    }

    cloneBatch(
        documents: Document[],
        destination: CloneDestination
    ): Observable<BatchResponseItem<DocumentClone>[] | HttpErrorResponse> {

        const payload: DocumentsCloneBatchParams = {
            destination,
            documentIds: documents.map((document) => (typeof document.id === 'object' ? document.id.documentId : document.id))
        };
        return this.http.post<BatchResponseItem<DocumentClone>[]>(this.url.cloneBatch(), payload).pipe(
            tap((docs) => {
                docs.forEach((responseItem) => {
                    const clone = responseItem.payload as DocumentClone;
                    const originalDocument = documents.find((d) => d.id === clone.oldId.documentId);

                    if (responseItem.statusCode !== 200) {
                        const rawMsg = (responseItem.payload as ApiErrorResponse).message;
                        const fullMessage = `An error occurred for ${originalDocument.title}. ${rawMsg}`;
                        this.Notifications.error(fullMessage || this.customErrorMessages);
                    }
                    else {
                        const msg = '<%= docName %> successfully duplicated.<br>'
                            + '<strong><a href="<%= url %>">GO TO DOCUMENT</a></strong>';
                        const msgTemplate = _.template(msg);
                        const lastVersion = _.last(clone.versions).number;
                        const navParams = this.getNavParams({ doc: clone, version: lastVersion });
                        const params = {
                            docName: originalDocument.name,
                            url: this.$state.href('app.team.document-show', navParams)
                        };
                        this.Notifications.success(msgTemplate(params));
                    }
                });
            }), catchError((error: HttpErrorResponse) => this.handleAndThrowError(error))
        );
    }

    updateLogMetadata(documentId: string, metadata: LogMetadata): Observable<Document | HttpErrorResponse> {
        return this.http.patch<Document>(this.url.logSingle(documentId), metadata).pipe(
            map((document) => {
                this.Notifications.success(this.generateMessageFromMetadata(metadata));
                return document;
            }),
            catchError((error) => {
                return this.handleAndThrowError(error);
            })
        );
    }

    addDoaLogRolesResponsibilities(
        documentId: string,
        { responsibilities, roles, reason }: { responsibilities: StudyResponsibilities, roles: StudyRole[], reason: string }
    ): Observable<Document | HttpErrorResponse> {
        const formattedRolesResponsibilities = LogTemplateUtil.formatDoaLogRolesResponsibilitiesToAdd(responsibilities, roles);
        const requestBody = {
            reason,
            ...formattedRolesResponsibilities
        };

        return this.http.patch<Document>(this.url.doaLogSingle(documentId), requestBody).pipe(
            tap(() => {
                this.Notifications.success('Successfully updated DOA Log Roles/Responsibilities.');
            }),
            catchError((error) => {
                return this.handleAndThrowError(error);
            })
        );
    }

    poll(id:string, version: number): Observable<Document> {
        return this.http
            .get<{ document: Document }>(this.url.poll({ id, version }))
            .pipe(
                map((response) => response.document),
                catchError((error: HttpErrorResponse) => {
                    this.Notifications.error(error.message || this.customErrorMessages);
                    throw error;
                })
            );
    }

    signDocument(
        doc: Document,
        signature: DocumentSignData | DocumentDeclineData,
        contentVersion: number
    ): Observable<void | HttpErrorResponse> {
        let signatureParam = signature;

        if (contentVersion && contentVersion !== doc.version) {
            signatureParam = _.merge({}, signature, { contentVersion });
        }

        return this.http.post<Document>(this.url.signatures({ id: doc.id, version: doc.version }), signatureParam)
            .pipe(
                map((response) => {
                    doc.updatedAt = response.updatedAt;
                    this.showDocumentSavedNotification({ signature });
                }),
                tap({
                    error: (errorResponse) => {
                        if (errorResponse.error.message === 'Signing PIN Expired.') {
                            errorResponse.error.message = 'Your Signing PIN has expired. Please reset your Signing PIN and try again.';
                        }
                    }
                }),
                catchError((error) => this.handleAndThrowError(error))
            );
    }

    private generateMessageFromMetadata(metadata: LogMetadata): string {
        if (metadata.logDetails) {
            return 'Log details successfully updated';
        }

        if (metadata.legend) {
            return 'Success! Legend updated!';
        }
        return 'Success! Log Information updated!';
    }

    private documentUploadErrorNotification(data: HttpErrorResponse, doc: File): void {
        const { error } = data;
        const message = this.resolveErrorMessage(error || {});
        const msgTemplate = _.template(message);
        const params = { docName: doc.name };

        this.Notifications.error({ message: msgTemplate(params), delay: 20000, closeOnClick: true });
        throwError(new Error(msgTemplate(params)));
    }

    private resolveErrorMessage(data = {}): string {

        const errorField = _.get(data, 'validation.keys[0]');
        const statusCode = _.get(data, 'statusCode');
        if (statusCode === 400 && errorField === 'filename') {
            return 'Document name contains invalid characters. Back "\\" slashes, forward "/" slashes, and colons ":" are not allowed.';
        }
        if (statusCode === 403) {
            return (data as { message: string }).message;
        }
        if (statusCode === 409) {
            return (data as { message: string }).message;
        }

        return 'Document <strong><%= docName %></strong> failed to upload.';
    }

    private showDocumentSavedNotification(params: ShowDocumentSavedNotificationParams): void {

        if (params.signature) {
            // regular signature 'sign'/'decline'
            return this.Notifications.success(`Document ${params.signature.status.toLowerCase()} successfully.`);
        }

        if (params.annotations) {
            if (_.find(params.annotations, ['type', 'signature'])) {
                // at least one of the annotations is a signature
                return this.Notifications.success('Document signed and annotated successfully.');
            }
            // just regular annotations
            return this.Notifications.success('Document annotated successfully.');
        }

        if (params.formFields) {

            if (!params.formFields.length) {

                if (params.shouldFinalize) {
                    // user clicked save after viewing the draft
                    return this.Notifications.success('Document finalized successfully.');
                }
                // not sure if this case can happen
                return this.Notifications.success('Document draft saved successfully.');
            }

            if (_.find(params.formFields, ['type', 'signature'])) {

                if (params.shouldFinalize) {

                    return this.Notifications.success('Document signed, annotated and finalized successfully.');
                }
                return this.Notifications.success('Document signed, annotated and draft saved successfully.');
            }

            if (params.shouldFinalize) {

                return this.Notifications.success('Document annotated and finalized successfully.');
            }

            return this.Notifications.success('Document annotated and draft saved successfully.');
        }
    }

    private getNavParams({ doc, version }: NavParamsInput): NavParams {
        const requestVersion = this.getDocRequestVersion(doc, version);
        const navParams = {
            teamId: doc.teamId,
            documentId: (typeof doc.id === 'object' ? doc.id.documentId : doc.id),
            version: requestVersion,
            contentVersion: version && version !== requestVersion ? version : null
        };
        return navParams as NavParams;
    }

    private handleAndThrowError(error: HttpErrorResponse): Observable<HttpErrorResponse> {
        const { error: { message } } = error;
        this.Notifications.error(message || this.customErrorMessages);
        return throwError(error);
    }
}
