
import {
    Component, Input, Output, EventEmitter, OnInit, Inject
} from '@angular/core';
import {
    FormBuilder, Validators, FormControl, FormArray, ValidatorFn
} from '@angular/forms';

import template from './multi-select-column-form.component.html';
import styles from './multi-select-column-form.component.scss';

@Component({
    selector: 'multi-select-column-form',
    template,
    styles: [String(styles)]
})
export class MultiSelectColumnFormComponent implements OnInit {
    @Input() multiselectColumnOptions: string[] = [];
    @Input() isNumber: boolean;
    @Input() optionValidators: ValidatorFn[] = [];
    @Output() multiselectColumnOptionsFormUpdated = new EventEmitter<string[]>();
    @Output() multiselectColumnIdentifierUpdated = new EventEmitter<boolean>();

    readonly maxAllowedOptions = 100;
    readonly defaultmultiselectNumberOfOptions = 2;
    readonly errorMessages = {
        length: 'Maximum of 100 characters exceeded.',
        required: 'Value cannot be empty'
    }

    optionsCount = this.multiselectColumnOptions.length || 2;
    multiselectColumnOptionsForm = this.fb.group({
        options: this.fb.array([], [
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(this.maxAllowedOptions)
        ])
    });

    identifierKeys: (string|number)[] = [];
    addNewOptionKey: string|number;

    isNumberIdentifier: boolean;

    constructor(private fb: FormBuilder, @Inject('Window') private window: Window) { }

    ngOnInit(): void {
        this.isNumberIdentifier = this.isNumber !== null ? this.isNumber : true;
        this.multiselectColumnOptionsForm.valueChanges.subscribe((value) => {
            this.onFormUpdated(value.options);
        });
        if (this.multiselectColumnOptions.length) {
            this.optionsCtrl.clear();
            this.multiselectColumnOptions.forEach((option, index) => {
                this.pushOptionControl(option);
                this.pushNewKeyIdentifier(index);
            });
        }
        else {
            for (let i = 0; i < this.defaultmultiselectNumberOfOptions; i += 1) {
                this.pushOptionControl();
                this.identifierKeys.push(i + 1);
            }
        }
    }

    trimValue(control: FormControl, onBlur: boolean) {
        const value = control.value.trim();

        if (value.length === 0 && !onBlur) {
            control.setErrors({ requiredErorr: this.errorMessages.required });
        }
        else if (onBlur) {
            control.setValue(value);
        }
    }

    get optionsCtrl(): FormArray {
        return this.multiselectColumnOptionsForm.controls.options as FormArray;
    }

    onFormUpdated(options: string[]): void {
        this.optionsCount = options.length;
        this.setAddNewOptionKey();
        this.optionsCtrl.pristine || this.multiselectColumnOptionsFormUpdated?.emit(this.optionsCtrl.value);
    }

    onAddOption() {
        if (this.optionsCount >= this.maxAllowedOptions) {
            return;
        }
        this.setAddNewOptionKey();
        this.pushOptionControl();
        this.pushNewKeyIdentifier();
    }

    pushOptionControl(option = '') {
        this.optionsCtrl.push(new FormControl(option, this.optionValidators));
    }

    pushNewKeyIdentifier(index?: number) {
        const itemIndex = index || this.identifierKeys.length;
        if (this.isNumberIdentifier) {
            this.identifierKeys.push(itemIndex + 1);
        }
        else {
            this.identifierKeys.push(this.numberToLetter(itemIndex + 1));
        }
    }

    removeOptionControl(index: number) {
        this.optionsCtrl.removeAt(index);
        this.identifierKeys.splice(index, 1);
        for (let i = index; i < this.identifierKeys.length; i += 1) {
            const value = this.getIndexDisplay(i);
            this.identifierKeys[i] = value;
        }
        this.setAddNewOptionKey();

        this.multiselectColumnOptionsFormUpdated.emit(this.optionsCtrl.value);
    }

    chooseIdentifier() {
        this.isNumberIdentifier = !this.isNumberIdentifier;
        for (let i = 0; i < this.optionsCount; i += 1) {
            const value = this.getIndexDisplay(i);
            this.identifierKeys[i] = value;
        }
        this.setAddNewOptionKey();

        this.multiselectColumnIdentifierUpdated.emit(this.isNumberIdentifier);
    }

    copyToClipboard() {

        const clipboardData = this.optionsCtrl.value.map((el, i) => {
            const value = this.getIndexDisplay(i);
            return `${value}. ${el}`;
        });

        const modifiedInput = JSON.stringify(clipboardData)
            .replace(/["[\]]/g, '') // removing [] and "" from an string
            .replace(/,/g, ',\n'); // Add a new line after each comma

        this.window.navigator.clipboard.writeText(modifiedInput);
    }

    getIndexDisplay(index: number): string {
        if (!this.isNumberIdentifier) {
            return this.numberToLetter(index + 1);
        }
        return (index + 1).toString();
    }

    numberToLetter(index: number): string {
        if (index <= 26) {
            return String.fromCharCode(64 + index); // A=65
        }
        const quotient = Math.floor((index - 1) / 26);
        const remainder = (index - 1) % 26;
        return this.numberToLetter(quotient) + this.numberToLetter(remainder + 1);
    }

    setAddNewOptionKey() {
        this.addNewOptionKey = this.isNumberIdentifier ? this.optionsCount + 1 : this.numberToLetter(this.optionsCount + 1);
    }

    get addOptionTooltipValue() {
        return this.optionsCount >= this.maxAllowedOptions
            ? `Maximum number of the items in the list is ${this.maxAllowedOptions}.`
            : null;
    }
}
