
import { Ng2StateDeclaration } from '@uirouter/angular';
import { ManageNotificationsComponent } from './containers/manage-notifications/manage-notifications.component';

const commonData = { requiresAuth: true };


export const notificationsRoute: Ng2StateDeclaration [] = [
    {
        name: 'app.team.manage-notifications',
        url: '/manage-notifications',
        params: {
            isTeamNotifications: false
        },
        component: ManageNotificationsComponent,
        data: commonData
    },
    {
        name: 'app.team.manage-team-notifications',
        url: '/manage-team-notifications',
        params: {
            isTeamNotifications: true
        },
        component: ManageNotificationsComponent,
        data: commonData
    }
];
