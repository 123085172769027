
import { Ng2StateDeclaration } from '@uirouter/angular';
import { HelpDeskComponent } from './containers/help-desk/help-desk.component';

const commonData = { requiresAuth: true };


export const helpDeskRoute: Ng2StateDeclaration [] = [
    {
        name: 'app.help-desk',
        url: '/help-desk?return_to',
        params: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            return_to: {
                dynamic: true
            }
        },
        component: HelpDeskComponent,
        data: commonData
    }
];
