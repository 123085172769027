
import { Ng2StateDeclaration } from '@uirouter/angular';
import { TemplateManagerFeatureGuard } from './template-manager-feature-guard';
import { ManageRolesComponent } from './containers/manage-roles/manage-roles.component';
import { ManageRolesTemplatesComponent } from './containers/manage-roles-templates/manage-roles-templates.component';
import { RolePermissionsComponent } from './containers/role-permissions/role-permissions.component';

const resolve = [
    {
        token: 'templateManagerFeatureGuard',
        deps: [TemplateManagerFeatureGuard],
        resolveFn: (guard: TemplateManagerFeatureGuard) => guard.canActivate().subscribe()
    }
];

const commonData = { requiresAuth: true };


export const rolesRoute: Ng2StateDeclaration [] = [
    {
        name: 'app.team.manage-roles',
        url: '/manage-roles',
        component: ManageRolesComponent,
        data: commonData
    },
    {
        name: 'app.team.manage-roles-templates',
        url: '/manage-roles/templates',
        component: ManageRolesTemplatesComponent,
        data: commonData,
        resolve
    },
    {
        name: 'app.team.role-permissions',
        url: '/manage-roles/:roleId',
        component: RolePermissionsComponent,
        data: commonData
    }
];
