import { PaginationModule } from 'ngx-bootstrap/pagination';

import { UIRouterModule } from '@uirouter/angular';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { WidgetsModule } from '@app/widgets/widgets.module';
import { SharedModule } from '@app/shared/shared.module';
import { AnnouncementsService } from './annoucements.service';
import { AnnouncementFormComponent } from './components/announcement-form/announcement-form.component';
import { UserRoleSelect } from './components/user-role-select/user-role-select.component';
import { AnnouncementsTableComponent } from './components/announcements-table/announcements-table.component';
import { AnnouncementSendComponent } from './containers/announcement-send/announcement-send.component';
import { AnnouncementViewComponent } from './containers/announcement-view/announcement-view.component';
import { AnnouncementsInboxComponent } from './containers/announcements-inbox/announcements-inbox.component';
import { announcementsRoutes } from './annoucements.routes';

@NgModule({
    imports: [
        CommonModule,
        WidgetsModule,
        ReactiveFormsModule,
        FormsModule,
        SharedModule,
        UIRouterModule.forChild({ states: announcementsRoutes }),
        PaginationModule.forRoot()
    ],
    providers: [
        AnnouncementsService
    ],
    declarations: [
        AnnouncementFormComponent,
        AnnouncementsTableComponent,
        AnnouncementSendComponent,
        AnnouncementViewComponent,
        UserRoleSelect,
        AnnouncementsInboxComponent
    ],
    entryComponents: [
        AnnouncementFormComponent,
        AnnouncementsTableComponent,
        AnnouncementSendComponent,
        AnnouncementViewComponent,
        UserRoleSelect,
        AnnouncementsInboxComponent
    ],
    exports: []
})
export class AnnouncementsModule { }
