import {
    Component, Input, Output, EventEmitter,
    ChangeDetectionStrategy,
    OnInit,
    OnDestroy,
    ViewChild,
    ElementRef
} from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import {
    debounceTime, distinctUntilChanged, filter, map, takeUntil
} from 'rxjs/operators';
import { Site, Study } from '@app/shared/models';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { FormControl } from '@angular/forms';
import template from './set-up-automation.component.html';
import { styles } from './set-up-automation.component.scss';
import { AbstractWizardStep } from '../../../../widgets/wizard/utils/abstract-wizard-step/abstract-wizard-step.component';


@Component({
    selector: 'set-up-automation-step',
    template,
    styles: [String(styles)],
    // eslint-disable-next-line no-use-before-define
    providers: [{ provide: AbstractWizardStep, useExisting: SetUpAutomationStepComponent }],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SetUpAutomationStepComponent extends AbstractWizardStep implements OnInit, OnDestroy {

    @ViewChild('studiesViewport', { static: false }) studiesViewport: CdkVirtualScrollViewport;
    @ViewChild('fetchDetect', { static: false }) fetchDetect: ElementRef;

    @Input() studies: Study[];
    @Input() selectedStudy: Study;
    @Input() totalStudiesCount: number;
    @Input() studySites: Site[];
    @Input() selectedSite: Site;
    @Input() isLoadingStudies: boolean;
    @Input() isLoadingStudySites: boolean;
    @Input() searchEnabled: boolean;

    @Output() filterStudies = new EventEmitter<string>();
    @Output() fetchMoreStudiesEvent = new EventEmitter<boolean>();
    @Output() fetchMoreStudySitesEvent = new EventEmitter<boolean>();
    @Output() selectStudyEvent = new EventEmitter<Study>();
    @Output() selectSiteEvent = new EventEmitter<Site>();
    @Output() addStudySiteTeamEvent = new EventEmitter<void>();

    placeholder = 'Select';
    showList = false;
    userSelectedStudy: Study;
    filterControl = new FormControl(null);
    hasNextBatch = false;
    readonly stepFormControlName = 'setupAutomation';
    private readonly destroy$ = new Subject<void>();

    ngOnInit(): void {
        this.syncStepValidityWithFormGroupStatusChanges$().pipe(
            takeUntil(this.destroy$)
        ).subscribe();

        this.filterControl.valueChanges.pipe(
            filter((v) => v !== null),
            takeUntil(this.destroy$),
            debounceTime(300),
            distinctUntilChanged((p, n) => {
                if (!!p && !n) {
                    this.filterStudies.emit('');
                    this.showList = true;
                }
                return p === n;
            })
        ).subscribe((value) => {
            this.filterStudies.emit(value);
        });

        this.stepFormGroup.updateValueAndValidity();
    }

    onScrolledIndexChange(current): void {
        const total = this.studiesViewport.getDataLength();
        if (current >= total) {
            this.loadMoreStudies();
        }
    }

    loadMoreStudies(): void {
        this.fetchMoreStudiesEvent.emit(true);
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    getMoreStudies() {
        this.fetchMoreStudiesEvent.emit(true);
    }

    getSites() {
        this.fetchMoreStudySitesEvent.emit(true);
    }

    selectStudy(study: Study) {
        this.selectStudyEvent.emit(study);
        this.filterControl.setValue(study.uniqueProtocolId);
        this.filterControl.markAsTouched();
        this.showList = false;
        this.userSelectedStudy = study;
    }

    selectSite(selectedSite: Site) {
        this.selectSiteEvent.emit(selectedSite);
    }

    addStudySiteTeam() {
        this.addStudySiteTeamEvent.emit();
    }

    studyNameInputText$(): Observable<string> {
        return of(this.selectedStudy).pipe(
            map((study: Study) => (study && study.nickname) ?? 'Study name')
        );
    }

    validStudySites$(): Observable<Site[]> {
        return of(this.studySites).pipe(
            map((sites: Site[]) => sites.filter((site) => {
                return !!site.customSiteId && site.teamMembers?.length > 0;
            }))
        );
    }

    private hasValidStudySites$(): Observable<boolean> {
        return this.validStudySites$().pipe(
            map((validStudySites: Site[]) => validStudySites.length > 0)
        );
    }

    isSiteIdInputDisabled$(): Observable<boolean> {
        return this.hasValidStudySites$().pipe(
            map((hasValidStudySites: boolean) => {
                if (this.isLoadingStudySites) {
                    return true;
                }
                return !this.selectedStudy?.uniqueProtocolId || !hasValidStudySites;
            })
        );
    }

    showAddStudySiteTeamElement$(): Observable<boolean> {
        return this.hasValidStudySites$().pipe(
            map((hasValidStudySites: boolean) => {
                if (this.isLoadingStudySites) {
                    return false;
                }
                return this.selectedStudy?.uniqueProtocolId && !hasValidStudySites;
            })
        );
    }

    onInputFocused(): void {
        this.showList = true;
    }

    toggleShowList(): void {
        this.showList = !this.showList;
    }

    onFilterChanged(event: string): void {
        this.filterControl.setValue(event);
        this.studiesViewport.scrollToIndex(0);
    }
}
