import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import {
    map, take, takeUntil, tap
} from 'rxjs/operators';
import { moveAwayFromUTC } from '@app/shared/date-time';
import template from './doa-log-automation-review-step.component.html';
import styles from './doa-log-automation-review-step.component.scss';
import { AbstractWizardStep } from '../../../../widgets/wizard/utils/abstract-wizard-step/abstract-wizard-step.component';
import { StudyResponsibilities, TeamMemberForm } from '../../../../shared/models';
import {
    TeamMemberChangedEvent,
    TeamMemberResponsibilitiesChangedEvent,
    TeamMemberStartDateChangedEvent,
    TeamMemberStudyRoleChangedEvent
} from './doa-log-automation-review-step.component.types';
import { StudyRoleIdName } from '../../../../shared/study-roles/study-roles.types';
import { LogTemplateUtil } from '../../../log-templates/utils/log-template.util';

@Component({
    selector: 'doa-log-automation-review-step',
    template,
    styles: [String(styles)],
    providers: [
        // eslint-disable-next-line no-use-before-define
        { provide: AbstractWizardStep, useExisting: DoaLogAutomationReviewStepComponent },
        { provide: '$scope', useExisting: '$rootScope' }
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DoaLogAutomationReviewStepComponent extends AbstractWizardStep implements OnInit, OnChanges, OnDestroy {
    @Input() teamMembers: TeamMemberForm[];
    @Input() studyRoles: StudyRoleIdName[] = [];
    @Input() responsibilities = <StudyResponsibilities>{};
    @Input() stepSelected = false;
    @Input() hasStartDateColumn = true;

    @Output() studyRoleChanged = new EventEmitter<TeamMemberStudyRoleChangedEvent>();
    @Output() responsibilitiesChanged = new EventEmitter<TeamMemberResponsibilitiesChangedEvent>();
    @Output() startDateChanged = new EventEmitter<TeamMemberStartDateChangedEvent>();
    @Output() bulkStartDateSelected = new EventEmitter<Date>();
    @Output() removeTeamMember = new EventEmitter<TeamMemberChangedEvent>();

    bulkStartDateValueChecked = false
    bulkStartDateValue: Date
    showWarningForMissingRoleFromTemplate = false
    clampLength = 25
    showPickerBulk: boolean;
    dateTimePositionConfig = {
        left: -135
    };

    dateTimeBulkPositionConfig = {
        left: -220,
        top: 40
    };


    readonly stepFormControlName = 'reviewAndCreate';
    private readonly destroy$ = new Subject<void>();

    ngOnInit(): void {
        this.syncStepValidityWithFormGroupStatusChanges$().pipe(
            takeUntil(this.destroy$)
        ).subscribe();

        this.stepFormGroup.updateValueAndValidity();
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    onTogglePicker(type: 'bulk' | 'single', user:TeamMemberForm): void {
        if (type === 'bulk') {
            this.showPickerBulk = !this.showPickerBulk;
        }
        else if (type === 'single' && user) {
            user.dropDownShow = !user.dropDownShow;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.stepSelected && changes.stepSelected.currentValue) {
            this.resetInputs();
        }
        else if (changes && changes.teamMembers && changes.teamMembers.currentValue) {
            this.updateWarningForMissingRoles();
        }
    }

    updateWarningForMissingRoles(): void {
        this.showWarningForMissingRoleFromTemplate = this.teamMembers.some(
            (teamMember) => !teamMember.responsibilityIds.length
        );
    }

    onSelectStudyRole(userId: string, studyRole: StudyRoleIdName): void {
        this.studyRoleChanged.emit({ userId, studyRole });
    }

    onRemoveTeamMember(teamMemberId: string) {
        this.removeTeamMember.emit({ userId: teamMemberId });
    }

    onResponsibilityChange(event: PointerEvent, userId: string, responsibilityId: string): void {
        event.stopPropagation();
        const inputElement = <HTMLInputElement>event.target;
        const { checked } = inputElement;

        this.teamMemberResponsibilities$(userId).pipe(
            tap((responsibilityIds) => {
                let updatedResponsibilityIds;
                if (checked) {
                    updatedResponsibilityIds = [...responsibilityIds, responsibilityId];
                }
                else {
                    updatedResponsibilityIds = responsibilityIds.filter((id) => id !== responsibilityId);
                }
                this.responsibilitiesChanged.emit({ userId, responsibilityIds: updatedResponsibilityIds });
            }),
            take(1)
        ).subscribe();
    }

    // TODO: confirm that startDate is the same type that the date picker emits
    onTeamMemberStartDateChange(userId: string, value: Date): void {
        const convertedDate = value ? new Date(moveAwayFromUTC(value)) : null;
        this.startDateChanged.emit({ userId, startDate: convertedDate });
    }

    onBulkStartDateChange(value: Date): void {
        this.bulkStartDateValue = value ? new Date(moveAwayFromUTC(value)) : null;
        this.bulkStartDateSelected.emit(this.bulkStartDateValue);
    }

    teamMemberResponsibilities$(userId: string): Observable<string[]> {
        return of(this.teamMembers).pipe(
            map((teamMembers) => {
                const teamMember = teamMembers.find((tm) => tm.userId === userId);

                return teamMember?.responsibilityIds || [];
            })
        );
    }

    selectedResponsibilityPositions$(userId: string): Observable<string> {
        const responsibilitiesDisplayPosition = [];

        return this.teamMemberResponsibilities$(userId).pipe(
            map((responsibilityIds) => {
                responsibilityIds.forEach((responsibilityId) => {
                    const responsibilityIndex = this.responsibilities.values
                        .findIndex(({ _id }) => _id === responsibilityId);

                    const displayPosition = LogTemplateUtil.getNumberOrLetterIdentifier(
                        responsibilityIndex, this.responsibilities.isNumberIdentifier
                    );
                    responsibilitiesDisplayPosition.push(displayPosition);
                });

                return responsibilitiesDisplayPosition.sort().join(', ');
            })
        );
    }

    isResponsibilityChecked$(userId: string, responsibilityId: string): Observable<boolean> {
        return of(this.teamMembers).pipe(
            map((teamMembers) => {
                const teamMember = teamMembers.find((tm) => tm.userId === userId);

                return teamMember?.responsibilityIds?.includes(responsibilityId);
            })
        );
    }

    toggleBulkStartDateSelection():void {
        this.bulkStartDateValueChecked = !this.bulkStartDateValueChecked;
    }

    checkTeamMemberResponsibility(teamMember: TeamMemberForm): boolean {
        this.updateWarningForMissingRoles();

        return !teamMember.responsibilityIds?.length;
    }

    resetInputs() {
        this.bulkStartDateValueChecked = false;
        this.bulkStartDateValue = null;
    }
}
