import * as _ from 'lodash';
import { Subject } from 'rxjs';
import * as Moment from 'moment-timezone';

import {
    MonitorReview,
    Document,
    DocumentSubTypes,
    SendToSipSiteInput,
    StudyWithTeamSipIntegrationLink,
    Team,
    User,
    SignatureTypes,
    AppConfig
} from '@app/shared/models';
import { StudiesService } from '@app/shared/studies/studies.service';
import { ALLOWED_PREVIEW_FILE_TYPES } from '@florencehealthcare/florence-constants/lib/documents';
import {
    FormSaveEvent, AnnotationSaveEvent, ClearAllEvent, Config
} from '@florencehealthcare/doc-viewer';
import { qcReviewStatuses } from '@florencehealthcare/florence-constants/lib/qc-reviews';
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import { ApproveReviewEvent, RejectReviewEvent } from '@app/components/qc-reviews/components/document-qc-review/document-handle-qc-review.component.types';
import template from './ndv.content.editor.component.html';
import styles from './ndv.content.editor.component.scss';
import { QcAcceptEvent, QcDeclineEvent } from '../log-toolbar/log-toolbar.component.types';
import { MonitorReviewsUpdateEvent } from '../monitor-review-actions/monitor-review-actions.component.types';

@Component({
    selector: 'ndv-content-editor',
    template,
    styles: [String(styles)]
})
export class NdvContentEditor implements OnInit, OnChanges, OnDestroy {
    @Input() doc: Document;
    @Input() appConfig: AppConfig;
    @Input() documentUrl: string;
    @Input() token: string;
    @Input() webviewerServerRangeRequests: boolean;
    @Input() currentDisplayVersion: number;
    @Input() hasSignatureRequest: boolean;
    @Input() signatureRequestType: SignatureTypes;
    @Input() requestIsPastDue: boolean;
    @Input() monitorReviewsEnabled: boolean;
    @Input() monitorReviews: MonitorReview[];
    @Input() currentTeam: Team;
    @Input() currentUser: User;
    @Input() jobTitleRequired = false;
    @Input() pageManipulationFeatureFlag = false;

    @Output() openTask = new EventEmitter<void>();
    @Output() replaceDocument = new EventEmitter<void>();
    @Output() reloadDocument = new EventEmitter<void>();
    @Output() onMonitorReviewsUpdate = new EventEmitter<MonitorReviewsUpdateEvent>();
    @Output() onRequestDownload = new EventEmitter<void>();
    @Output() onSetVersion = new EventEmitter<number>();
    @Output() onQcAcceptDocument = new EventEmitter<QcAcceptEvent>();
    @Output() onQcDeclineDocument = new EventEmitter<QcDeclineEvent>();
    @Output() onApproveQcReview = new EventEmitter<ApproveReviewEvent>();
    @Output() onRejectQcReview = new EventEmitter<RejectReviewEvent>();
    @Output() onError = new EventEmitter<string>();
    @Output() onClearAll = new EventEmitter<ClearAllEvent>();
    @Output() onSaveAnnotationChanges = new EventEmitter<AnnotationSaveEvent>();
    @Output() onFormSave = new EventEmitter<FormSaveEvent>();
    @Output() onSign = new EventEmitter<void>();
    @Output() onSignatureDecline = new EventEmitter<void>();

    allowFilePreview = true;

    private readonly destroy$ = new Subject<void>();

    isDocumentLocked = false;
    docViewerToolsConfig: Config;
    showQcAcceptButton = false;
    showQcDeclineButton = false;
    showQcReviewButton = false;
    qcReviewModal = false;
    qcReviewAction: string;
    isProcessingAccept = false;
    isProcessingDecline = false;
    review = null;
    comment = '';
    isProcessing: boolean;
    isRejected: boolean;
    isCancelled: boolean;
    reviewToConduct;
    showMonitorDropdown: boolean;
    showManageDropdown: boolean;
    showSendDropdown: boolean;
    latestVersion: number;
    showWarnings: boolean;
    isStudyLoading = true;
    sitesWithSipLink: SendToSipSiteInput[];

    constructor(
        private Studies: StudiesService
    ) {}

    ngOnInit(): void {
        this.allowFilePreview = this.isFileTypePreviewAllowed();
        this.setNewDocViewerConfig();

        this.latestVersion = Math.max(...this.doc.versions.map((v) => v.number));

        this.calculateShowManageDropdown();

        this.getSitesWithSipLink();
        this.isDocumentLocked = this.doc.isLocked;
        this.review = this.doc.qcReview;
        this.qcButtonAcceptVisible();
        this.qcButtonDeclineVisible();
        this.qcButtonReviewVisible();
    }

    setNewDocViewerConfig(): void {
        this.docViewerToolsConfig = {
            annotationsFeatureConfig: {
                highlight: this.canHighlight(),
                timestamp: this.canTimestamp(),
                freeTextTool: this.canAddText()
            },
            signaturesFeatureConfig: {
                enabled: this.canSign(),
                stampSignatureText: this.formatStampSignature(),
                addendum: this.canSignAddendum(),
                stamp: this.canSignStamp()
            },
            initialDocument: {
                isPDFForm: this.doc.formStatus === 'form-in-progress',
                url: this.documentUrl,
                filename: this.doc.name + this.doc.ext,
                locked: this.doc.isLocked,
                hasPendingSignatureRequest: this.hasSignatureRequest,
                hasPendingSignatureExpired: this.hasSignatureRequest && this.requestIsPastDue
            },
            pendo: {
                apiKey: this.appConfig.pendoApiKey,
                initData: {
                    visitor: {
                        id: this.currentUser.id
                    },
                    account: {
                        id: this.currentTeam.id,
                        teamName: this.currentTeam.name,
                        environment: this.appConfig.environment
                    }
                }
            },
            redact: this.canRedact(),
            previewOnly: this.doc.isLocked || this.isRejected || this.isCancelled || this.doc.formStatus === 'checking-form',
            disableSignatureFieldsReadOnly: this.currentTeam.settings.features.disableSignatureFieldsReadOnly,
            manipulatePages: this.canManipulatePages(),
            pageRotation: this.canRotatePages(),
            pageDeletionAndReordering: this.canDeleteAndReorderPages(),
            timezone: Moment().tz(Moment.tz.guess()).format('z'),
            token: this.token,
            licenseKey: this.appConfig.docViewerLicenseKey,
            webviewerServerUrl: this.appConfig.webviewerServerUrl,
            disableVirtualDisplayMode: this.doc.formStatus === 'form-in-progress',
            webviewerServerRangeRequests: this.webviewerServerRangeRequests
        };
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.doc) {
            this.isDocumentLocked = this.doc.isLocked;
            this.checkWarnings();
        }
        if (changes.doc?.currentValue.qcReview && !changes.doc.isFirstChange()) {
            this.qcButtonAcceptVisible();
            this.qcButtonDeclineVisible();
            this.qcButtonReviewVisible();
            this.review = this.doc.qcReview;
        }

        this.setNewDocViewerConfig();

    }

    handleOpenTask() {
        this.openTask.emit();
    }

    private canHighlight(): boolean {
        return this.doc.isLatestVersion
            && !this.doc?.isShortcut
            && this.doc?.permissions?.highlightDocument;
    }

    private canRedact(): boolean {
        return this.doc.isLatestVersion
            && !this.doc?.isShortcut
            && this.doc?.permissions?.redactDocument;
    }

    private canAddText(): boolean {
        return this.doc.isLatestVersion
            && !this.doc?.isShortcut
            && this.doc?.permissions?.addTextToDocument;
    }

    private canTimestamp(): boolean {
        return this.doc.isLatestVersion
            && !this.doc?.isShortcut
            && this.doc?.permissions?.timestampDocument;
    }

    private canSign(): boolean {
        return !this.isOldDocVersion()
            && this.currentTeam?.settings?.signatures
            && this.doc?.permissions?.signDocument;
    }

    private canSignSignatureRequestType(signatureRequestType: string): boolean {
        if (this.hasSignatureRequest) {
            return (
                this.signatureRequestType === SignatureTypes.any
                || this.signatureRequestType === signatureRequestType
            );
        }
        return true;
    }

    private canSignAddendum(): boolean {
        return this.canSign()
            && !this.currentTeam?.settings?.signatures?.disableAddendum
            && this.canSignSignatureRequestType(SignatureTypes.addendum)
            && !this.requestIsPastDue;
    }

    private canSignStamp(): boolean {
        return this.canSign()
            && !this.doc?.isShortcut
            && !this.currentTeam?.settings?.signatures?.disableAnnotation
            && this.canSignSignatureRequestType(SignatureTypes.stamp)
            && !this.requestIsPastDue;
    }

    private canManipulatePages(): boolean {
        return this.doc?.permissions?.documentManipulatePages
            && !this.doc?.isShortcut
            && (this.doc?.formStatus === 'no-form' || this.doc?.formStatus === 'form-finalized')
            && this.pageManipulationFeatureFlag;
    }

    private canRotatePages(): boolean {
        return this.canManipulatePages()
        || (
            this.doc?.permissions?.documentRotatePages
            && !this.doc?.isShortcut
            && (this.doc?.formStatus === 'no-form' || this.doc?.formStatus === 'form-finalized')
            && this.pageManipulationFeatureFlag
        );
    }

    private canDeleteAndReorderPages(): boolean {
        return this.canManipulatePages()
        || (
            this.doc?.permissions?.documentDeleteAndReorderPages
            && !this.doc?.isShortcut
            && (this.doc?.formStatus === 'no-form' || this.doc?.formStatus === 'form-finalized')
            && this.pageManipulationFeatureFlag
        );
    }

    private formatStampSignature(): string {
        let result = '[Electronic Signature]';

        if (
            this.currentUser.fullName
            && this.currentUser.fullName !== this.currentUser.email
        ) {
            result += `\n${this.currentUser.fullName} - ${this.currentUser.email}`;
        }
        else {
            result += `\n${this.currentUser.email}`;
        }

        result += '\n[Date and time - set on save]';
        result += '\n[Reason - set on save]';

        if (this.jobTitleRequired) {
            if (this.currentUser.profile.jobTitle) {
                result += `\nJob Title - ${this.currentUser.profile.jobTitle}`;
            }
            else {
                result += '\n[Job Title - set on save]';
            }
        }

        return result;
    }

    private getSitesWithSipLink(): void {
        const { teamId } = this.doc;
        const documentId = this.getDocumentId();

        this.Studies.getDocumentStudyProfile(teamId, documentId).subscribe(
            (studies) => {
                if (studies && studies.length) {
                    this.sitesWithSipLink = this.getSitesDataWithSipLink(studies);
                    this.isStudyLoading = false;
                    this.canBeExported();
                }
            }
        );
    }

    private getDocumentId(): string {
        return typeof this.doc.id !== 'string'
            ? this.doc.id.documentId
            : this.doc.id;
    }

    private getSitesDataWithSipLink(studies: StudyWithTeamSipIntegrationLink[]): SendToSipSiteInput[] {
        const sitesWithSipLink: SendToSipSiteInput[] = [];

        studies && studies.forEach((study) => {
            study.sites && study.sites.forEach((site) => {
                if (site.teamSipIntegrationLink) {
                    sitesWithSipLink.push({
                        studyId: study.id,
                        siteId: site.id,
                        uniqueProtocolId: study.uniqueProtocolId,
                        siteName: site.siteName
                    });
                }
            });
        });
        return sitesWithSipLink;
    }

    qcButtonAcceptVisible() {
        if (this.doc.qcReview && this.doc.permissions.conductQCReview) {

            const nextReviewer = this.doc.qcReview.reviews.findIndex(
                (element) => element.reviewerIds.id === this.currentUser.id
                    && element.status === qcReviewStatuses.PENDING_REVIEW
            );

            switch (nextReviewer) {
                case 0:
                    this.showQcAcceptButton = true;
                    break;
                case -1:
                    this.showQcAcceptButton = false;
                    break;
                default:
                    this.showQcAcceptButton = this.checkPreviousCycles(nextReviewer);
            }
        }
    }

    qcButtonDeclineVisible() {
        if (this.doc.qcReview && this.doc.permissions.conductQCReview) {

            const nextReviewer = this.doc.qcReview.reviews.findIndex(
                (element) => element.reviewerIds.id === this.currentUser.id
                    && (element.status === qcReviewStatuses.PENDING_REVIEW || element.status === qcReviewStatuses.IN_REVIEW)
            );

            switch (nextReviewer) {
                case 0:
                    this.showQcDeclineButton = true;
                    break;
                case -1:
                    this.showQcDeclineButton = false;
                    break;
                default:
                    this.showQcDeclineButton = this.checkPreviousCycles(nextReviewer);
            }
        }
    }

    qcButtonReviewVisible() {
        if (this.doc.qcReview && this.doc.permissions.conductQCReview) {

            const nextReviewer = this.doc.qcReview.reviews.findIndex(
                (element) => element.reviewerIds.id === this.currentUser.id
                    && (element.status === qcReviewStatuses.IN_REVIEW
                        || element.status === qcReviewStatuses.IN_REJECTION_REVIEW)
            );

            switch (nextReviewer) {
                case 0:
                    this.showQcReviewButton = true;
                    break;
                case -1:
                    this.showQcReviewButton = false;
                    break;
                default:
                    this.showQcReviewButton = this.checkPreviousCycles(nextReviewer);
            }

            if (nextReviewer >= 0) {
                this.reviewToConduct = this.doc.qcReview.reviews[nextReviewer];
                this.qcReviewAction = this.reviewToConduct.status === qcReviewStatuses.IN_REJECTION_REVIEW ? 'REVISIT QC REVIEW' : 'QC REVIEW';
            }
        }
    }

    checkPreviousCycles(currentCycle: number): boolean {
        for (let i = 0; i < currentCycle; i += 1) {
            if (this.doc.qcReview.reviews[i].status !== qcReviewStatuses.APPROVED) {
                return false;
            }
        }
        return true;
    }

    acceptQcReview() {
        this.isProcessingAccept = true;
        const event = {
            docId: this.doc.id as string,
            docVersion: this.doc.version,
            onSuccess: () => {
                this.isProcessingAccept = false;
            },
            onError: () => {
                this.isProcessingAccept = false;
            }
        };
        this.handleQcAcceptDocument(event);
    }

    declineQcReview() {
        const event = {
            docId: this.doc.id as string,
            docVersion: this.doc.version,
            onError: () => {
                this.isProcessingDecline = false;
            },
            onClose: () => {
                this.isProcessingDecline = false;
            }
        };

        this.isProcessingDecline = true;
        this.onQcDeclineDocument.emit(event);
    }

    handleQcAcceptDocument(event: QcAcceptEvent) {
        this.onQcAcceptDocument.emit(event);
    }

    handleQcDeclineDocument(event: QcDeclineEvent) {
        this.onQcDeclineDocument.emit(event);
    }

    handleApproveQcReview($event: ApproveReviewEvent) {
        this.onApproveQcReview.emit($event);
    }

    handleRejectQcReview($event: RejectReviewEvent) {
        this.onRejectQcReview.emit($event);
    }

    handleClearAll($event: ClearAllEvent) {
        this.onClearAll.emit($event);
    }

    handleDecline() {
        this.onSignatureDecline.emit();
    }

    handleAddendumSign() {
        this.onSign.emit();
    }

    handleSaveAnnotationChanges($event: AnnotationSaveEvent) {
        this.onSaveAnnotationChanges.emit($event);
    }

    handleSaveForm($event: FormSaveEvent) {
        this.onFormSave.emit($event);
    }

    openCloseQcReview($event: { comment: string }) {
        this.comment = $event ? $event.comment : this.comment;
        this.qcReviewModal = !this.qcReviewModal;
    }

    approveReview($event: ApproveReviewEvent) {
        this.handleApproveQcReview($event);
    }

    rejectReview($event: RejectReviewEvent) {
        this.handleRejectQcReview($event);
    }

    private isOldDocVersion() {
        return this.doc.isShortcut
            ? (!this.doc.isLocked || this.doc.shortcutOf.lockedToDocumentVersion !== this.currentDisplayVersion)
                && this.doc.originalDocument
                && !this.doc.originalDocument.isLatestVersion
            : !this.doc.isLatestVersion;
    }

    private checkWarnings(): void {
        const status = this.doc.documentProperties?.approval?.status;
        this.isRejected = status === 'rejected';
        this.isCancelled = status === 'cancelled';

        const isOldVersion = this.isOldDocVersion();

        this.showWarnings = this.isRejected
            || this.isCancelled
            || isOldVersion;
        this.showWarnings = this.showWarnings || this.doc.formStatus === 'checking-form';

    }

    calculateShowManageDropdown(): void {
        const { permissions, isLatestVersion, isDownloadable } = this.doc;
        const canAssignTags = permissions.assignTags;
        const canManageTasks = isLatestVersion && permissions.manageDocTasks;
        const canDestroyDoc = permissions.destroyDocument;
        const canDuplicate = isLatestVersion && permissions.duplicateDocument;
        const canMove = isLatestVersion && permissions.moveDocument;
        const canRequestSignatures = permissions.requestSignature;
        const canSign = permissions.signDocument;
        const hasQcReview = !!this.doc.qcReview && !this.doc.isLocked;
        const canRequestQCReview = (this.doc.permissions.documentCreateEditQCReview
            || this.doc.permissions.manageQCreview
            || this.doc.permissions.qcReviewAdministrator)
            && this.doc.qcReview === undefined
            && this.canQcReviewDocBySubtypeAndStatus()
            && (this.doc.documentProperties?.approval?.status !== 'rejected')
            && !this.doc.isLocked;

        this.showManageDropdown = canAssignTags
            || canManageTasks
            || canDestroyDoc
            || isDownloadable
            || canDuplicate
            || canMove
            || canRequestSignatures
            || canSign
            || hasQcReview
            || canRequestQCReview;
    }

    canQcReviewDocBySubtypeAndStatus() {

        switch (this.doc.subType) {
            case 'content':
                if (this.doc.conversionStatus === 'Complete' && (this.doc.formStatus === 'no-form' || this.doc.formStatus === 'form-finalized')) {
                    return true;
                }
                return false;
            case 'log':
                return true;
            case 'shortcut':
                return false;
            case 'placeholder':
                return false;
            default:
                return false;
        }

    }

    canAddendumSign(): boolean {
        return !_.get(this.currentTeam, 'settings.signatures.disableAddendum', false);
    }

    canBeExported(): void {
        this.showSendDropdown = this.doc
            && this.doc.subType === DocumentSubTypes.content
            && this.doc.permissions.sendDocSIP
            && this.doc.hasPii === false
            && !!this.sitesWithSipLink.length;
    }

    onRejectAndUpdate() {
        this.reloadDocument.emit();
    }

    handleDocumentViewerHandleError($event: string) {
        this.onError.emit($event);
    }

    handleMonitorReviewsUpdate($event: MonitorReviewsUpdateEvent) {
        this.onMonitorReviewsUpdate.emit($event);
    }

    handleRequestDownload() {
        this.onRequestDownload.emit();
    }

    handleReplaceDocument() {
        this.replaceDocument.emit();
    }

    setToLatestVersion() {
        this.setToVersion(this.latestVersion);
    }

    setToVersion(version: number) {
        this.onSetVersion.emit(version);
    }

    isDocumentWithoutPreview(): boolean {
        return !this.allowFilePreview;
    }

    private isFileTypePreviewAllowed(): boolean {
        return ALLOWED_PREVIEW_FILE_TYPES.includes(this.doc.ext?.toLowerCase());
    }
}
