import { Document, Binder, Folder } from '@app/shared/models';
import { VirtualTreeFlatNode } from '../../widgets/virtual-tree/virtual-tree.component.types';
import { AcknowledgeTypesValues } from './team-inbox.constants';

export const TeamInboxFilterableProperties = {
    DOCUMENT_NAME: 'name'
} as const;

export interface GetTeamInboxParams {
    teamId: string;
    pageNumber?: number;
    pageSize?: number;
    filterType?: typeof TeamInboxFilterableProperties.DOCUMENT_NAME;
    filterValue?: string;
}

export interface PatchInboxDocumentParams {
    teamId: string;
    documentId: string;
    action: AcknowledgeTypesValues;
    destination: VirtualTreeFlatNode,
}

export interface InboxDocument extends Document {
    id: {
        documentId: string;
        version: number;
    };
    documentType: string;
    requiredAction: AcknowledgeTypesValues;
    suggestedLocation: Binder | Folder;
}

export interface TeamInboxResponse {
    recordCount: number;
    pageNumber: number;
    items: InboxDocument[];
}

export interface Pagination {
    pageNumber: number;
    pageSize?: number;
    sortBy?: string;
    sortDir?: string;
    recordCount?: number;
}

export interface Filter {
    filterValue: string;
    filterType?: typeof TeamInboxFilterableProperties.DOCUMENT_NAME;
}
