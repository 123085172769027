import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WidgetsModule } from '@app/widgets/widgets.module';
import { UIRouterModule } from '@uirouter/angular';
import { SharedModule } from '../../shared/shared.module';
import { ModalsModule } from '../../widgets/modals/modals.module';
import { NotificationSettingsComponent } from './components/notification-settings-modal/notification-settings.component';
import { ManageNotificationsComponent } from './containers/manage-notifications/manage-notifications.component';
import { notificationsRoute } from './notifications.routes';
@NgModule({
    imports: [
        ModalsModule,
        CommonModule,
        SharedModule,
        UIRouterModule.forChild({ states: notificationsRoute }),
        WidgetsModule
    ],
    providers: [
    ],
    declarations: [
        ManageNotificationsComponent,
        NotificationSettingsComponent
    ],
    entryComponents: [
        ManageNotificationsComponent,
        NotificationSettingsComponent
    ],
    exports: [
        ManageNotificationsComponent,
        NotificationSettingsComponent
    ]
})
export class NotificationsModule {}
