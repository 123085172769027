'use strict';

module.exports = {
    TEAM_PROFILE: 'team_profile',
    PAYWALLS: 'paywalls',
    TEAM_MEMBERS: 'team_members',
    FOLDER_STRUCTURE: 'folder_structure',
    USERS: 'users',
    TAGS: 'tags',
    LOG_TEMPLATES: 'log_templates',
    ANNOUNCEMENTS: 'announcements',
    INTERNALS: 'internals',
    UNCATEGORIZED: 'uncategorized',
    USER_PROFILE: 'user_profile',
    STUDY_STRUCTURE_TEMPLATES: 'study_structure_templates',
    STUDY_PROFILES: 'study_profiles',
};
