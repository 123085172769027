import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { WidgetsModule } from '@app/widgets/widgets.module';
import { SharedModule } from '@app/shared/shared.module';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { StudyManagerComponent } from './containers/study-manager/study-manager.component';
import { CreateStudyComponent } from './containers/create-study/create-study.component';
import { UpdateStudySelectStudyComponent } from './components/update-study-select-study/update-study-select-study.component';
import { UpdateStudyComponent } from './containers/update-study/update-study.component';
import { StudyFormComponent } from './components/study-form/study-form.component';
import { ReviewStudyComponent } from './components/review-study/review-study.component';
import { SelectLocationComponent } from './components/select-location/select-location.component';
import { StudyManagerService } from './study-manager.service';
import { UpdateStudyModal } from './components/update-study-modal/update-study-modal.component';
import { CreateStudyModal } from './components/create-study-modal/create-study-modal.component';
import { JobStatusTableComponent } from './containers/job-status-table/job-status-table.component';
import { studyManagerRoutes } from './study-manager.routes';

@NgModule({
    imports: [
        CommonModule,
        WidgetsModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        UIRouterModule.forChild({ states: studyManagerRoutes }),
        PaginationModule.forRoot(),
        BsDropdownModule.forRoot()
    ],
    providers: [
        StudyManagerService
    ],
    declarations: [
        StudyManagerComponent,
        CreateStudyComponent,
        UpdateStudySelectStudyComponent,
        UpdateStudyComponent,
        ReviewStudyComponent,
        StudyFormComponent,
        SelectLocationComponent,
        UpdateStudyModal,
        CreateStudyModal,
        JobStatusTableComponent
    ],
    entryComponents: [
        StudyManagerComponent,
        CreateStudyComponent,
        UpdateStudySelectStudyComponent,
        UpdateStudyComponent,
        ReviewStudyComponent,
        StudyFormComponent,
        SelectLocationComponent,
        UpdateStudyModal,
        CreateStudyModal,
        JobStatusTableComponent
    ],
    exports: []
})
export class StudyManagerModule { }
