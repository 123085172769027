import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { ReactiveFormsModule } from '@angular/forms';

import { WidgetsModule } from '@app/widgets/widgets.module';
import { SharedModule } from '@app/shared/shared.module';
import { StudyStartupComponent } from './containers/study-startup/study-startup.component';
import { ImportTemplateStructureComponent } from './containers/import-template-structure/import-template-structure.component';
import { ImportStructureComponent } from './components/import-structure/import-structure.component';
import { StudyStartupService } from './study-startup.service';
import { ImportStructureDetailsComponent } from './components/import-structure-details/import-structure-details.component';
import { ImportedStructuresTableComponent } from './components/imported-structures-table/imported-structures-table.component';
import { ImportedStructurePreviewComponent } from './components/imported-structure-preview/imported-structure-preview.component';
import { DestroyStructureTemplateComponent } from './components/destroy-structure-template/destroy-structure-template.component';
import { AssignStructureTemplateBinderComponent } from './containers/assign-structure-template-binder/assign-structure-template-binder.component';
import { AssignStructureTemplateFolderComponent } from './containers/assign-structure-template-folder/assign-structure-template-folder.component';
import { ChooseBinderOptionsComponent } from './components/assign-structure-template/choose-binder-options/choose-binder-options.component';
import { CreateNewOptionComponent } from './components/create-new-option/create-new-option.component';
import { ChooseFolderOptionsComponent } from './components/assign-structure-template/choose-folder-options/choose-folder-options.component';
import { ChooseExistingOptionComponent } from './components/choose-existing-option/choose-existing-option.component';
import { ApplyDueDatesComponent } from './containers/apply-due-dates/apply-due-dates.component';
import { studyStartupRoutes } from './study-startup.routes';

@NgModule({
    imports: [
        CommonModule,
        WidgetsModule,
        ReactiveFormsModule,
        SharedModule,
        UIRouterModule.forChild({ states: studyStartupRoutes })
    ],
    providers: [
        StudyStartupService
    ],
    declarations: [
        AssignStructureTemplateBinderComponent,
        AssignStructureTemplateFolderComponent,
        ApplyDueDatesComponent,
        ChooseBinderOptionsComponent,
        ChooseFolderOptionsComponent,
        ChooseExistingOptionComponent,
        StudyStartupComponent,
        ImportTemplateStructureComponent,
        ImportedStructurePreviewComponent,
        ImportStructureComponent,
        ImportStructureDetailsComponent,
        ImportedStructuresTableComponent,
        DestroyStructureTemplateComponent,
        CreateNewOptionComponent
    ],
    entryComponents: [
        AssignStructureTemplateBinderComponent,
        AssignStructureTemplateFolderComponent,
        ApplyDueDatesComponent,
        ChooseBinderOptionsComponent,
        ChooseFolderOptionsComponent,
        ChooseExistingOptionComponent,
        StudyStartupComponent,
        ImportTemplateStructureComponent,
        ImportedStructurePreviewComponent,
        ImportStructureComponent,
        ImportStructureDetailsComponent,
        ImportedStructuresTableComponent,
        DestroyStructureTemplateComponent,
        CreateNewOptionComponent
    ],
    exports: []
})
export class StudyStartupModule { }
