import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EntityModule } from '@app/widgets/entity/entity.module';

import { CurrentSessionService } from '@app/core/current-session.service';
import { WidgetsModule } from '@app/widgets/widgets.module';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { UIRouterModule } from '@uirouter/angular';
import { SessionActivityService } from './session-activity.service';
import { SessionActivityDetailsComponent } from './components/session-activity-details/session-activity-details.component';
import { SessionsActivityShowComponent } from './containers/session-activity-show/sessions-activity-show.component';
import { sessionRoute } from './sessions.routes';

@NgModule({
    imports: [
        UIRouterModule.forChild({ states: sessionRoute }),
        CommonModule,
        EntityModule,
        WidgetsModule,
        TooltipModule
    ],
    providers: [
        SessionActivityService,
        CurrentSessionService
    ],
    declarations: [
        SessionActivityDetailsComponent,
        SessionsActivityShowComponent
    ],
    entryComponents: [
        SessionActivityDetailsComponent,
        SessionsActivityShowComponent
    ],
    exports: []
})
export class SessionActivityShowModule {}
