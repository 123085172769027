import { UIRouterModule } from '@uirouter/angular';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EntityModule } from '@app/widgets/entity/entity.module';
import { WidgetsModule } from '@app/widgets/widgets.module';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { SharedModule } from '@app/shared/shared.module';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { MatTabsModule } from '@angular/material/tabs';
import { DocViewerModule, DocViewerComponent } from '@florencehealthcare/doc-viewer';
import { DocumentTimelineListComponent } from '@app/components/documents/components/document-timeline-list/document-timeline-list.component';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { DocumentPlaceholderFillComponent } from './components/document-placeholder-fill/document-placeholder-fill.component';
import { DocumentActionsComponent } from './components/document-actions/document-actions.component';
import { DocumentDownloadComponent } from './components/document-download/document-download.component';
import { DocumentEditDetailsComponent } from './components/document-edit-details/document-edit-details.component';
import { DocumentExportModalComponent } from './components/document-export/document-export.component';
import { DocumentSendComponent } from './components/document-send/document-send.component';
import { DocumentVersionsComponent } from './components/document-versions/document-versions.component';
import { SignDocumentComponent } from './components/sign-document/sign-document.component';
import { SignLogEntriesComponent } from './components/sign-log-entries/sign-log-entries.component';
import { LogEditMetadataComponent } from './components/log-edit-metadata/log-edit-metadata.component';
import { LogEntryFormComponent } from './components/log-entry-form/log-entry-form.component';
import { LogEntryRowComponent } from './components/log-entry-row/log-entry-row.component';
import { StrikeThroughInfoRowComponent } from './components/strikethrough-info-row/strikethrough-info-row.component';
import { PinExpiryWarningComponent } from './components/pin-expiry-warning/pin-expiry-warning.component';
import { PreviousVersionWarningComponent } from './components/previous-version-warning/previous-version-warning.component';
import { DocumentLogContentComponent } from './containers/document-log-content/document-log-content.component';
import { DocumentLogEntriesComponent } from './containers/document-log-entries/document-log-entries.component';
import { SignatureCommentsViewerComponent } from './components/signature-comments-viewer/signature-comments-viewer.component';
import { LogCreateComponent } from './components/log-create/log-create.component';
import { MissingSigningPasscodeComponent } from './components/missing-signing-passcode/missing-signing-passcode.component';
import { ViewRelatedDocumentsDetailsComponent } from './components/view-related-documents-details/view-related-documents-details.component';
import { DocumentMonitorReviewService } from './document-monitor-review.service';
import { AnnotationsModalComponent } from './components/annotations-modal/annotations-modal.component';
import { SignatureDisabledModalComponent } from './components/signature-disabled/signature-disabled.component';
import { DestroyDocumentComponent } from './components/destroy-document/destroy-document.component';
import { DocumentLockingModalComponent } from './components/document-locking-modal/document-locking-modal.component';
import { LogImportRowsComponent } from './components/log-bulk-import-rows/log-import-rows/log-import-rows.component';
import { LogImportRowsPreviewComponent } from './components/log-bulk-import-rows/log-import-rows-preview/log-import-rows-preview.component';
import { DocumentLockedWatermarkComponent } from './components/document-locked-watermark/document-locked-watermark.component';
import { DocumentBulkSetDatesComponent } from './components/document-bulk-set-dates/document-bulk-set-dates.component';
import { DocumentRequestQCReviewComponent } from '../qc-reviews/components/document-request-qc-review/document-request-qc-review.component';
import { LogToolbarComponent } from './components/log-toolbar/log-toolbar.component';
import { DocumentDeclineQcReviewComponent } from '../qc-reviews/components/document-decline-qc-review/document-decline-qc-review.component';
import { QcReviewGlobalStatusComponent } from '../qc-reviews/components/qc-review-global-status/qc-review-global-status.component';
import { ViewManageQcReviewComponent } from '../qc-reviews/components/view-manage-qc-review/view-manage-qc-review.component';
import { DocumentHandleQcReviewComponent } from '../qc-reviews/components/document-qc-review/document-handle-qc-review.component';
import { RemoveQcReviewCycleComponent } from '../qc-reviews/components/remove-qc-review-cycle/remove-qc-review-cycle.component';
import { DeleteQcReviewComponent } from '../qc-reviews/components/delete-qc-review/delete-qc-review.component';
import { QcReviewDetailsComponent } from '../qc-reviews/components/qc-review-details/qc-review-details.component';
import { AddChangeQcReviewComponent } from '../qc-reviews/components/add-change-qc-review/add-change-qc-review.component';
import { ShortcutDetailsComponent } from './components/shortcut-details/shortcut-details.component';
import { ReviewFindingsQcReviewComponent } from '../qc-reviews/components/review-findings-qc-review/review-findings-qc-review.component';
import { ReviewFindingsConcludedComponent } from '../qc-reviews/components/review-finding-concluded/review-findings-concluded.component';
import { DocumentSharingModule } from '../document-sharing/document-sharing.module';
import { LogEntryStrikethroughComponent } from './components/log-entry-strikethrough/log-entry-strikethrough.component';
import { DocumentReplaceComponent } from './components/document-replace/document-replace.component';
import { DocumentFormComponent } from './components/document-form/document-form.component';
import { DocumentSignatureListComponent } from './components/document-signature-list/document-signature-list.component';
import { DocumentApprovalComponent } from './components/document-approval/document-approval.component';
import { DocumentTaskListComponent } from './components/document-task-list/document-task-list.component';
import { MonitorReviewListComponent } from './components/monitor-review-list/monitor-review-list.component';
import { AnnotationsClearAllModalComponent } from './components/annotations-clear-all-modal/annotations-clear-all-modal.component';
import { AssignCategoryComponent } from './components/assign-category/assign-category.component';
import { DocumentSignaturesComponent } from './components/document-signatures/document-signatures.component';
import { MonitorReviewEventsComponent } from './components/monitor-review-events/monitor-review-events.component';
import { MonitorReviewPotentialRecipientsComponent } from './components/monitor-review-potential-recipients/monitor-review-potential-recipients.component';
import { MonitorReviewIntegrationFormComponent } from './components/monitor-review-integration-form/monitor-review-integration-form.component';
import { MonitorReviewEditComponent } from './components/monitor-review-edit/monitor-review-edit.component';
import { SignatureCompleteComponent } from './components/signature-complete/signature-complete.component';
import { MonitorReviewApproveComponent } from './components/monitor-review-approve/monitor-review-approve.component';
import { MonitorReviewActionsComponent } from './components/monitor-review-actions/monitor-review-actions.component';
import { DeclineDocumentComponent } from './containers/document-decline/document-decline.component';
import { DocumentHistoryModalComponent } from './components/document-history-modal/document-history-modal.component';
import { DocumentPlaceholderDetailsComponent } from './components/document-placeholder-details/document-placeholder-details.component';
import { NdvContentEditor } from './components/ndv-content-editor/ndv.content.editor.component';
import { DocumentViewerUnsupportedComponent } from './document-content-editor/document.viewer.unsupported/document.viewer.unsupported.component';
import { NdvFormSaveComponent } from './components/ndv-form-save/ndv-form-save.component';
import { DefaultSigningReasonService } from './document-content-editor/services/default-signing-reason.service';
import { TaskFormComponent } from './components/task-form/task-form.component';
import { DocumentDetailsComponent } from './components/document-details/document-details.component';
import { SetUpAutomationStepComponent } from './components/set-up-automation-step/set-up-automation.component';
import { DoaLogAutomationModal } from './components/doa-log-automation-modal/doa-log-automation-modal';
import { DoaLogAutomationReviewStepComponent } from './components/doa-log-automation-review-step/doa-log-automation-review-step.component';
import { DocumentsShowComponent } from './containers/document-show/document-show.component';
import { documentsRoute } from './documents.routes';

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        BsDropdownModule.forRoot(),
        TooltipModule.forRoot(),
        TypeaheadModule.forRoot(),
        PaginationModule.forRoot(),
        CommonModule,
        SharedModule,
        WidgetsModule,
        EntityModule,
        ScrollingModule,
        DocumentSharingModule,
        UIRouterModule.forChild({ states: documentsRoute }),
        DocViewerModule,
        MatTabsModule
    ],
    providers: [
        DocumentMonitorReviewService,
        DefaultSigningReasonService,
        BsModalRef
    ],
    declarations: [
        AnnotationsModalComponent,
        AnnotationsClearAllModalComponent,
        DocumentActionsComponent,
        DocumentEditDetailsComponent,
        DocumentExportModalComponent,
        DocumentFormComponent,
        DocumentVersionsComponent,
        DocumentLogContentComponent,
        DocumentLogEntriesComponent,
        DocumentSendComponent,
        LogEntryFormComponent,
        LogEntryStrikethroughComponent,
        SignDocumentComponent,
        SignLogEntriesComponent,
        SignatureCommentsViewerComponent,
        LogCreateComponent,
        LogEditMetadataComponent,
        LogEntryRowComponent,
        StrikeThroughInfoRowComponent,
        PreviousVersionWarningComponent,
        PinExpiryWarningComponent,
        DocumentDownloadComponent,
        MissingSigningPasscodeComponent,
        ViewRelatedDocumentsDetailsComponent,
        SignatureDisabledModalComponent,
        DestroyDocumentComponent,
        DocumentLockingModalComponent,
        LogImportRowsComponent,
        LogImportRowsPreviewComponent,
        DocumentLockedWatermarkComponent,
        DocumentBulkSetDatesComponent,
        DocumentRequestQCReviewComponent,
        LogToolbarComponent,
        DocumentDeclineQcReviewComponent,
        QcReviewGlobalStatusComponent,
        ViewManageQcReviewComponent,
        DocumentHandleQcReviewComponent,
        RemoveQcReviewCycleComponent,
        DeleteQcReviewComponent,
        QcReviewDetailsComponent,
        AddChangeQcReviewComponent,
        ReviewFindingsQcReviewComponent,
        ReviewFindingsConcludedComponent,
        DocumentSignatureListComponent,
        DocumentReplaceComponent,
        ShortcutDetailsComponent,
        DocumentApprovalComponent,
        DocumentTaskListComponent,
        AssignCategoryComponent,
        DocumentTimelineListComponent,
        MonitorReviewListComponent,
        DocumentSignaturesComponent,
        MonitorReviewEventsComponent,
        MonitorReviewIntegrationFormComponent,
        MonitorReviewEditComponent,
        SignatureCompleteComponent,
        MonitorReviewPotentialRecipientsComponent,
        MonitorReviewApproveComponent,
        MonitorReviewActionsComponent,
        DeclineDocumentComponent,
        DocumentPlaceholderFillComponent,
        DocumentHistoryModalComponent,
        DocumentPlaceholderDetailsComponent,
        NdvContentEditor,
        DocumentViewerUnsupportedComponent,
        NdvFormSaveComponent,
        TaskFormComponent,
        DocumentDetailsComponent,
        SetUpAutomationStepComponent,
        DoaLogAutomationReviewStepComponent,
        DoaLogAutomationModal,
        DocumentsShowComponent
    ],
    entryComponents: [
        ShortcutDetailsComponent,
        AnnotationsModalComponent,
        AnnotationsClearAllModalComponent,
        DocumentActionsComponent,
        DocumentEditDetailsComponent,
        DocumentExportModalComponent,
        DocumentVersionsComponent,
        DocumentLogContentComponent,
        DocumentLogEntriesComponent,
        DocumentSendComponent,
        LogEntryFormComponent,
        LogEntryStrikethroughComponent,
        SignDocumentComponent,
        SignLogEntriesComponent,
        SignatureCommentsViewerComponent,
        LogCreateComponent,
        LogEditMetadataComponent,
        LogEntryRowComponent,
        StrikeThroughInfoRowComponent,
        PreviousVersionWarningComponent,
        PinExpiryWarningComponent,
        DocumentDownloadComponent,
        MissingSigningPasscodeComponent,
        ViewRelatedDocumentsDetailsComponent,
        SignatureDisabledModalComponent,
        DestroyDocumentComponent,
        DocumentLockingModalComponent,
        LogImportRowsComponent,
        LogImportRowsPreviewComponent,
        DocumentLockedWatermarkComponent,
        DocumentBulkSetDatesComponent,
        DocumentRequestQCReviewComponent,
        LogToolbarComponent,
        DocumentDeclineQcReviewComponent,
        QcReviewGlobalStatusComponent,
        ViewManageQcReviewComponent,
        DocumentHandleQcReviewComponent,
        RemoveQcReviewCycleComponent,
        DeleteQcReviewComponent,
        QcReviewDetailsComponent,
        AddChangeQcReviewComponent,
        ReviewFindingsQcReviewComponent,
        ReviewFindingsConcludedComponent,
        DocViewerComponent,
        DocumentFormComponent,
        DocumentSignatureListComponent,
        DocumentReplaceComponent,
        DocumentFormComponent,
        DocumentApprovalComponent,
        DocumentTaskListComponent,
        AssignCategoryComponent,
        DocumentTimelineListComponent,
        MonitorReviewListComponent,
        DocumentSignaturesComponent,
        MonitorReviewEventsComponent,
        MonitorReviewPotentialRecipientsComponent,
        MonitorReviewIntegrationFormComponent,
        MonitorReviewEditComponent,
        SignatureCompleteComponent,
        MonitorReviewApproveComponent,
        MonitorReviewActionsComponent,
        DocumentPlaceholderFillComponent,
        DeclineDocumentComponent,
        DocumentHistoryModalComponent,
        DocumentPlaceholderDetailsComponent,
        NdvContentEditor,
        DocumentViewerUnsupportedComponent,
        NdvFormSaveComponent,
        TaskFormComponent,
        DocumentDetailsComponent,
        SetUpAutomationStepComponent,
        DoaLogAutomationReviewStepComponent,
        DoaLogAutomationModal,
        DocumentsShowComponent
    ],
    exports: []
})
export class DocumentsModule { }
