
import { Ng2StateDeclaration } from '@uirouter/angular';
import { StudyStartupComponent } from './containers/study-startup/study-startup.component';
import { StudyStartupRouteGuard } from './study-startup-route-guard';

const resolve = [
    {
        token: 'templateManagerFeatureGuard',
        deps: [StudyStartupRouteGuard],
        resolveFn: (guard: StudyStartupRouteGuard) => guard.checkPermissions().subscribe()
    }
];

const commonData = { requiresAuth: true };


export const studyStartupRoutes: Ng2StateDeclaration [] = [
    {
        name: 'app.team.study-startup',
        url: '/study-startup',
        component: StudyStartupComponent,
        data: commonData,
        resolve
    }
];
