import { Ng2StateDeclaration } from '@uirouter/angular';
import { FolderShowComponent } from './containers/folder-show/folder-show.component';

const commonData = { requiresAuth: true };


export const foldersRoutes: Ng2StateDeclaration [] = [
    {
        name: 'app.team.folder',
        url: '/binders/:binderId/folders/:folderId',
        params: {
            folderId: ''
        },
        component: FolderShowComponent,
        data: commonData
    }
];
