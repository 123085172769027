import { Inject, Injectable } from '@angular/core';
import { CurrentSessionService } from '@app/core/current-session.service';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { AppConfigService } from '@app/shared/app-config/app-config.service';
import { Team } from '@app/shared/models';
import { TeamService } from '@app/shared/teams/team.service';
import { Transition } from '@uirouter/angular';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class CurrentTeamResolver {
    constructor(
        @Inject('Window') private $window: Window,
        private teamService: TeamService,
        private currentSession: CurrentSessionService,
        private notifications: NotificationsService,
        private appConfigService: AppConfigService
    ) {}

    resolveCurrentTeam(transition: Transition): Observable<void | Team> {
        const { teamId } = transition.params();

        return this.teamService.loadById(teamId).pipe(
            tap((team) => this.currentSession.setCurrentTeam(team)),
            catchError((error) => {
                this.notifications.error(error);
                return throwError(() => error);
            })
        );
    }

    redirectToSelectTeam(): void {
        const url = `${this.appConfigService.config.authHost}/#/app/select-team`;
        this.$window.location.replace(url);
    }


}
