import { Injectable } from '@angular/core';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { AppFeaturesService } from '@app/shared/app-features/app-features.service';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})
export class TemplateManagerFeatureGuard {
    constructor(
    private appFeatures: AppFeaturesService,
    private notifications: NotificationsService
    ) {}

    canActivate(): Observable<boolean> {
        return this.appFeatures.assertRoleTemplateManagerEnabledForCurrentTeam().pipe(
            map(() => true),
            catchError((error) => {
                this.notifications.error(error);
                return new Observable<boolean>((observer) => observer.error());
            })
        );
    }
}
