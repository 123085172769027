
import { Ng2StateDeclaration } from '@uirouter/angular';
import { UserProfileComponent } from './containers/user-profile/user-profile.component';

const commonData = { requiresAuth: true };


export const userProfileRoutes: Ng2StateDeclaration [] = [
    {
        name: 'app.user-profile',
        url: '/user-profile',
        component: UserProfileComponent,
        data: commonData
    }
];
